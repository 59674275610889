import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "shared/Checkbox/Checkbox";
import { amenitieAssignment, Item } from "types/Item/Item";

interface AmenitiesSectionProps {
  currentItem?: Item;
  setSelectedAmenities: Dispatch<SetStateAction<amenitieAssignment[]>>;
  selectedAmenities: amenitieAssignment[];
}

const AmenitiesSection = ({
  currentItem,
  setSelectedAmenities,
  selectedAmenities,
}: AmenitiesSectionProps) => {
  const { t } = useTranslation();
  const { amenitie_assignments } = currentItem ?? {};

  const onSelectCheckbox = (id: number) => {
    const amenitieObject = amenitie_assignments?.find(
      ({ id_amenitie }) => id_amenitie === id
    );

    if (amenitieObject) {
      const isSelected = selectedAmenities.find(
        ({ id_amenitie }) => amenitieObject.id_amenitie === id_amenitie
      );

      if (isSelected) {
        setSelectedAmenities((prev) =>
          prev.filter(
            (amenitie) => amenitie.id_amenitie !== amenitieObject.id_amenitie
          )
        );

        return;
      }

      setSelectedAmenities((prev) => [...prev, amenitieObject]);
    }
  };

  return (
    <div className="listingSection__wrap">
      <div>
        <h2 className="text-2xl font-semibold">{t("amenities")}</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {t("resortDetail-amenities-description")}
        </span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
        {amenitie_assignments?.map(({ amenitie, price, IS_REQUIRED }) => (
          <div key={amenitie.id_amenitie} className="flex items-center gap-4">
            <Checkbox
              defaultChecked={IS_REQUIRED}
              disabled={IS_REQUIRED}
              label={amenitie.detail_amenities}
              name={`amenitie-${amenitie.id_amenitie}`}
              onChange={() => onSelectCheckbox(amenitie.id_amenitie)}
              key={amenitie.id_amenitie}
            />

            {!!price && <span className="font-bold">(${price})</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AmenitiesSection;

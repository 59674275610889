import { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";
import { PriceRangeSelector } from "../PriceRangeSelector";

interface Props {}
const FiltersModal = (props: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <NcModal
      contentPaddingClass="pb-12 pt-6 px-8"
      contentClass="flex flex-col gap-12"
      modalTitle={t("searchFilterModal-title")}
      onCloseModal={() => setIsOpen(false)}
      isOpenProp={isOpen}
      renderTrigger={() => (
        <ButtonSecondary
          onClick={() => setIsOpen(true)}
          className="rounded-md "
        >
          Filters
        </ButtonSecondary>
      )}
    >
      <section className="w-full h-full flex flex-col gap-4">
        <header>
          <h3 className="font-bold text-primary-6000 text-lg">
            {t("searchFilterModal-priceRange")}
          </h3>
        </header>

        <div>
          <PriceRangeSelector />
        </div>
      </section>
      <section className="text-center">
        <strong>{t("searchFilterModal-workingOnMoreFilters")}</strong>
      </section>
    </NcModal>
  );
};
export default FiltersModal;

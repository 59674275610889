import { ICustomer, ICreateCustomer } from "types/Customer";
import { ILoginCustomer } from "types/Customer/Customer";
import api from "./api";

const API_ENDPOINT = '/customers'
const API_LOGIN_ENDPOINT = '/api-login'

interface ICustomerLoginResponse {
  message: string,
  token: string,
  error: boolean
  customer: ICustomer
}

export interface ICustomerResponse {
  message: string,
  error: boolean,
  customer?: ICustomer
}

const getCustomerData = async (id_customer: number | string) => (await api.get<ICustomerResponse>(`${API_ENDPOINT}/${id_customer}`)).data

const createCustomer = async (newCustomerData: ICreateCustomer) => (await api.post<ICustomerResponse>(API_ENDPOINT, newCustomerData)).data

const loginAsCustomer = async (loginCustomerData: ILoginCustomer) => (await (api.post<ICustomerLoginResponse>(API_LOGIN_ENDPOINT, loginCustomerData))).data

export { createCustomer, loginAsCustomer, getCustomerData } 

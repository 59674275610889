const ENVS = {
  apigatewaypaymentlocal: "http://localhost:8080",
  apigatewaypaymentprod: "https://mipasareladepago.com",
  local: "http://tourbooking-backend.test",
  prod: "https://tour-admin.stagecr.com/tourbooking-backend/public",
};

const env = ENVS.prod;

const apiPaymentGateway = ENVS.apigatewaypaymentprod;

export { env, apiPaymentGateway };

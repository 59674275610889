import { TimeRage } from "components/HeroSearchForm/FlightSearchForm";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Item } from "types/Item/Item";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import { Link } from 'react-router-dom';
import RentalCarsDatePicker from "components/HeroSearchForm/RentalCarsDatePicker";
import { IReservation } from "types/Reservation";

interface SidebarSectionProps {
  dateRangeValue: Moment;
  timeRangeValue?: TimeRage;
  setDateRangeValue: (date: any) => void;
  setTimeRangeValue?: (date: any) => void;
  handleSubmitReservation: () => void;
  currentItem?: Item;
  totalPrice: number;
  differenceDays: number;
  isReserved?: boolean;
  reservationData: IReservation,
  seatsTaken?: number
}

function SidebarPriceSection({
  currentItem,
  dateRangeValue,
  timeRangeValue,
  setDateRangeValue,
  setTimeRangeValue,
  totalPrice,
  differenceDays,
  handleSubmitReservation,
  isReserved,
  reservationData,
  seatsTaken
}: SidebarSectionProps) {
  const { t } = useTranslation();

  return (
    <div className="listingSectionSidebar__wrap shadow-xl">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <span className="text-3xl font-semibold">${currentItem?.amount_item}{" "}</span>
          <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
            {" "}
            / {t("day").toLocaleLowerCase()}{" "}
          </span>
        </div>
        <div className="w-fit bg-yellow-200 rounded-md px-3 py-1 mt-2">
          {
            currentItem &&
            <span className="text-sm text-slate-700 font-medium italic">
              {(seatsTaken ?? 0) > 10 ? "Only Few Seats Available" : "Full Seats Available"} 
            </span>
          }
        </div>
      </div>

      <form className="flex border  border-neutral-200 dark:border-neutral-700 rounded-3xl ">
        <RentalCarsDatePicker
          date={dateRangeValue}
          handleChange={(date: any) => setDateRangeValue(date)}
          disabled={isReserved}
        />
      </form>

      <div className="flex flex-col space-y-4 ">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>
            ${currentItem?.amount_item} x {differenceDays}{" "}
            {t("pluralDay").toLocaleLowerCase()}
          </span>
          <span>${totalPrice}</span>
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="flex justify-between font-semibold">
          <span>Total</span>
          <span>${totalPrice}</span>
        </div>
      </div>

      {/* <ButtonPrimary disabled={isReserved} onClick={handleSubmitReservation}>
        {t("rent")}
      </ButtonPrimary> */}

      <Link to={{
        pathname: `/tour-modal-page`,
        search: `?id_item=${currentItem?.id_item}`,
        state: { ...reservationData },
      }}>
        <ButtonPrimary
          className="w-full"
        >
          Go To Checkout
        </ButtonPrimary>
      </Link>
    </div>
  );
}

export default SidebarPriceSection;

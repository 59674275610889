import { IAmenity } from "types/Amenity";
import api from "./api";

export interface IAmenititesRespose {
  message: string,
  error: boolean,
  amenities: IAmenity[],
}

const API_ENDPOINT = '/data/amenities'

const getAmenities = async () => ((await api.get<IAmenititesRespose>(API_ENDPOINT)).data)

export { getAmenities } 
import React from "react";
import { useLocation } from "react-router-dom";
import Header2 from "components/Header/Header";
import Header from "shared/Header/Header";
import LegalRoutes from "./LegalRoutes";

const SiteHeader = () => {
  let location = useLocation();
  return LegalRoutes.find(route => {
    if(location.pathname.includes(route)) return true
  }) ? (
    null
  ) : (
    <Header />
  );
};

export default SiteHeader;

import { getSpecialDayPrice } from "containers/TourModalPage/utils/getSpecialDayPrice";
import { getSeasonPrice } from "containers/TourModalPage/utils/getSeasonPrice";

import moment, { Moment } from "moment";
import { Item } from "types/Item/Item";

const getTourDayPrice = (calendar_date: Moment, currentItem: Item | undefined, type = 'adults'): number | undefined => {
    if(!currentItem) return 0;
    let total = currentItem?.amount_item || 0;
    const special_tours_days = currentItem?.special_tours_days || [];
    const high_season = currentItem?.high_season;
    const low_season = currentItem?.low_season;

    if(special_tours_days.length > 0)
      total =  getSpecialDayPrice(calendar_date, currentItem) ?? total;
    if(high_season)
      total = getSeasonPrice(calendar_date, high_season, type) ?? total;
    if(low_season)
      total = getSeasonPrice(calendar_date, low_season, type) ?? total;
    

    return total;
  }



export { getTourDayPrice };

import { createPayment, createPaymentBacApiPaymentGateway } from "api/paymentApi";
import { FormControl } from "components/FormControl";
import { ReservationResponse } from "containers/TourModalPage/types/ReservationRespose";
import { requiredRule, patternRule, maxLengthRule, trimInputRule } from "helpers/formRulesHelper";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import NcModal from "shared/NcModal/NcModal";
import Textarea from "shared/Textarea/Textarea";
import { BacPaymentResponse, BacPaymentData } from "types/Payment";
import { IReservation } from "types/Reservation";
import FullScreenLoaderComponent from "components/FullScreenLoaderComponent";
import { Alert } from "shared/Alert/Alert";
import PaymentFailedIconCreditCard from "../../images/credit-card.png";
import { CreditCard } from "components/CreditCard/CreditCard";
import api from "api/api";

interface PaymentModalProps {
//   reservationData: IReservation;
  amount: number;
  setResgisterStatus: Dispatch<SetStateAction<number>>;
  setSubmitPayment: Dispatch<SetStateAction<boolean>>;
  submitPayment: boolean;
  reservation?: ReservationResponse;
  createReservation: () => Promise<ReservationResponse>;
}

interface PaymentForm {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  email: string;
  cardNumber: string;
  cardCvv: string;
  cardHolderName: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
}

const BacCheckoutForm = ({
  amount,
  submitPayment,
  setResgisterStatus,
  reservation,
  createReservation,
  setSubmitPayment,
}: PaymentModalProps) => {
  const { t } = useTranslation();
  const { ...methods } = useForm<PaymentForm>();
  const [done, setDone] = useState<Boolean | string>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const closeModal = () => {
     setOpenModal(false);
   };

   const [state, setState] = useState({
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    focus: ""
})

const handleInputChange = (e:any) => {
  setState({
    ...state,
    [e.target.name] : e.target.value
})
 
}

const handleFocusChange = (e: any) => {
    setState({
        ...state,
        focus : e.target.name
    })
}
 
  const onSubmit = async () => {

    setIsLoading(true);

    const newPaymentDataAdapter: BacPaymentData = {
      amount: amount,
      cardExpiration: `${state.expiry.substring(0,2)}/${state.expiry.substring(2)}`,
      cardholderName: state.name,
      cardPan: state.number,
      cardCvv: state.cvc,
    };

    if(!newPaymentDataAdapter.cardExpiration || !newPaymentDataAdapter.cardholderName || !newPaymentDataAdapter.cardPan || !newPaymentDataAdapter.cardCvv) {
      setOpenModal(true)
      setIsLoading(false);
      setSubmitPayment(false);
    }
    try {
      const payToGateway = (await createPaymentBacApiPaymentGateway(
        newPaymentDataAdapter
        ));
        if(payToGateway.status === 200) {
          await confirmPayment();
          setSubmitPayment(false);
          setIsLoading(false);   
        }

        if(payToGateway.status !== 200) {
          setIsLoading(false);
          setSubmitPayment(false);
          setOpenModal(true)
        }

    } catch (error) {
      return null;
    }
  };

  const confirmPayment = async () => {
   const { reservation } = await createReservation();
   await api.post('/confirmPayment', {
      id_reservation: reservation?.id_reservation,
      supplier: 'bac'
    });
    setResgisterStatus(1);
  }

  useEffect(() =>{ 
    if(submitPayment === true){
      onSubmit();
    }
  }, [submitPayment]);

  return (
    <>
     <NcModal
              isOpenProp={openModal}
              onCloseModal={() => closeModal()}
              modalTitle={t("Payment failed")}
              contentPaddingClass="p-4"
              triggerText={null}
            >
              <div className="flex justify-center">
                <div className="w-full h-64 overflow-y-auto rounded-lg border-gray-200 p-2 text-justify">
                  <span className="block text-sm text-slate-500">
                  <div className="flex justify-center sm:(min-width: 380px) mt-3">
                        <img src={PaymentFailedIconCreditCard} alt="success" width="15%" />
                  </div>
                  <div className="flex justify-center sm:(min-width: 380px) mt-3">
                        <p className="text-2xl font-bold text-red-600">{t("Error payment processing")}</p>
                  </div>
                  <div className="flex justify-center sm:(min-width: 380px) mt-3">
                        <p className="text-sm font-bold text-slate-600">{t("This error may be caused by any causes, try again or if you still have problems contact us.")}</p>
                  </div>
                  </span>
                </div>
              </div>
      </NcModal>
        {!done ? (
        <FormProvider {...methods}>
          <input type="text" autoFocus={true} style={{ display: 'none' }} />
          <div className="w-full px-0 sm:px-8 lg:col-span-9 xl:col-span-6">
          <CreditCard
                    number={state.number}
                    name={state.name}
                    expiry={state.expiry}
                    cvc={state.cvc}
                    focused={state.focus}
                    />
          </div>
            <div className="w-full flex justify-center">
                <form>
                <div className="w-full flex gap-2">
                    <FormControl
                        id="payment-modal-cardNumber"
                        name="number"
                        label={t("paymentModal-cardNumber")}
                        registerOptions={{
                          required: requiredRule(t("requiredField")),
                          pattern: patternRule(/^[0-9]+$/),
                          validate: trimInputRule(t("invalidField")),
                        }}
                      >
                        {(field: any) => (
                          <Input
                            autoFocus
                            className="form-control"
                            onChange={handleInputChange}
                            onFocus={handleFocusChange}  
                            type="text"
                            maxLength={16}
                            placeholder="XXXX XXXX XXXX XXXX"
                            {...field}
                          />
                        )}
                      </FormControl>

                      <FormControl
                          id="payment-modal-cardCvv"
                          name="cvc"
                          label="CVC" 
                          registerOptions={{
                            required: requiredRule(t("requiredField")),
                            pattern: {
                              value: /^[0-9]+$/, 
                              message: t("invalidField")
                            },
                            validate: trimInputRule(t("invalidField")) 
                          }}
                        >
                          {(field: any) => (
                            <Input 
                              className="form-control"
                              type="number"
                              onKeyDown={(e) => {
                                if(state.cvc.length >= 3 && e.key !== "Backspace") {
                                  e.preventDefault();
                                  return false; 
                                }
                              }}
                              placeholder="XXX"
                              onChange={handleInputChange}  
                              onFocus={handleFocusChange}
                              {...field}  
                            />
                          )}
                        </FormControl>
                    
                    </div>
              
                    <div className="w-full flex gap-2 items-end">
                      <FormControl
                        id="payment-modal-cardHolderName"
                        name="name"
                        label={t("paymentModal-cardHolderName")}
                        registerOptions={{
                          required: requiredRule(t("requiredField")),
                          validate: trimInputRule(t("invalidField")),
                        }}
                      >
                        {(field: any) => (
                          <Input
                          type="text"
                          className="form-control"
                          maxLength={20}
                          onChange={handleInputChange}
                          onFocus={handleFocusChange}
                          placeholder="John Doe" {...field} />
                        )}
                      </FormControl>

                      <FormControl
                            id="payment-modal-cardExpiration"
                            name="expiry"
                            className="w-1/2"
                            label={t("paymentModal-cardExpiration")}
                            registerOptions={{
                              required: requiredRule(t("requiredField")),
                              validate: trimInputRule(t("invalidField")),
                            }}
                          >
                            {(field: any) => (
                              <Input
                                required
                                type="number"
                                className="form-control"
                                onKeyDown={(e) => {
                                  if(state.expiry.length >= 4 && e.key !== "Backspace") {
                                    e.preventDefault();
                                    return false; 
                                  }
                                }}
                                placeholder={t("xxxx")}
                                onChange={handleInputChange}
                                onFocus={handleFocusChange}
                                {...field}
                              />
                            )}
                          </FormControl>
                    </div>
                    <FullScreenLoaderComponent isLoading={isLoading} />

                    {/* <button onClick={onSubmit} type="button" className="btn btn-success btn-block btn-lg">Pagar</button> */}
                </form>
              </div>
        </FormProvider>
      ) : (
        <iframe width={"100%"} title="doc" srcDoc={done as string} />
      )}
    </>
  );
};

export type { PaymentModalProps };
export default BacCheckoutForm;

import moment, { Moment } from "moment";
import { IReservationCounter, Item } from "types/Item/Item";
import { IReservation } from "types/Reservation";

function getRange(startDate: Date, endDate: Date, type: any) {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, type);
  let range = [];
  for (let i = 0; i < diff; i++) {
    range.push(moment(startDate).add(i, type));
  }
  return range;
}

const getReservedData = (
  item: Item | undefined,
  calendar_day: Moment
) => {

  const reservations = item?.reservations;
  const max_passengers = item?.max_passengers || 0;

  //Se obtienen las reservaciones del dia
  const calendar_day_reservations = reservations
    ?.filter(({init_reservation} : IReservation ) => {
      let reservation_moment_datetime_format = moment(new Date(init_reservation));
      return reservation_moment_datetime_format.format("YYYY-MM-DD") == calendar_day.format("YYYY-MM-DD");
    })

  let total_passengers_calendar_day = 0;
  calendar_day_reservations?.map(({passengers_count}) => {
    return total_passengers_calendar_day += (passengers_count ? passengers_count : 0)
  },0)

  const status = 
    total_passengers_calendar_day >= max_passengers
      ? {
          IS_AVAILABLE: false,
          capacity_porc: 100,
        }
      : total_passengers_calendar_day >= (max_passengers / 2)
      ? {
          IS_AVAILABLE: true,
          capacity_porc: (total_passengers_calendar_day / max_passengers) * 100,
        }
      : {
          IS_AVAILABLE: true,
          capacity_porc: (total_passengers_calendar_day / max_passengers) * 100,
        };

  return status;
};

const checkIfIsReserved = (
  targetDates: [Date, Date?],
  reservationsArr: IReservation[] = []
) => {
  let isReserved;
  const [startDate, endDate] = targetDates;
  const adaptedReservationsDates = reservationsArr.map(
    ({ init_reservation, end_reservation }) => ({
      startDate: moment(init_reservation),
      endDate: moment(end_reservation),
    })
  );

  // If only is searching by one specific date
  if (!endDate) {
    const adaptedTargetDate = moment(startDate);
    isReserved = adaptedReservationsDates.some(({ startDate, endDate }) =>
      adaptedTargetDate.isBetween(startDate, endDate, "days", "[]")
    );
  } else {
    // If is searching by date range
    // First get all car reservations and map them into array's which contains every day date between that reservation date range
    const adaptedTargetDatesRange = reservationsArr.map(
      ({ init_reservation, end_reservation }) =>
        getRange(init_reservation as any, end_reservation as any, "days")
    );

    // Compare if some date from every date range is between searched dates
    isReserved = adaptedTargetDatesRange.some((_dateRange) =>
      _dateRange.some((_date) =>
        _date.isBetween(startDate, endDate, "days", "[]")
      )
    );
  }

  return isReserved;
};

export { checkIfIsReserved, getReservedData };

import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import {
  ListingResortMapPage,
  ListingResortPage,
} from "containers/ListingResortsPages";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingResortDetailPage";
import { LoginPage } from "containers/LoginPage";
import { SignUpPage } from "containers/SignUpPage";
import { StripeSuccesfulPage } from "containers/StripeSuccesfulPage";
import SiteHeader from "containers/SiteHeader/SiteHeader";
import TourModalPage from "containers/TourModalPage/TourModalPage";
import { ToastContainer } from "react-toastify";

export const pages: Page[] = [
  { path: "/", exact: true, component: ListingResortPage },
  { path: "/signup", exact: true, component: SignUpPage },
  { path: "/login", exact: true, component: LoginPage },
  { path: "/listing-car-map", component: ListingResortMapPage },
  { path: "/listing-tour-detail", component: ListingCarDetailPage },
  { path: "/stripe/sucessful-payment", component: StripeSuccesfulPage },
  { path: "/tour-modal-page", component: TourModalPage }
];

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>

      <ToastContainer />
      {/* <Footer /> */}
    </BrowserRouter>
  );
};

export default Routes;

import { createPayment } from "api/paymentApi";
import { FormControl } from "components/FormControl";
import { requiredRule, patternRule } from "helpers/formRulesHelper";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import NcModal from "shared/NcModal/NcModal";
import Textarea from "shared/Textarea/Textarea";
import { BacPaymentResponse, BacPaymentData } from "types/Payment";
import { IReservation } from "types/Reservation";

interface PaymentModalProps {
  isOpen: boolean;
  reservationData: IReservation;
  onCloseModal: () => void;
}

interface PaymentForm {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  email: string;
  cardNumber: string;
  cardCvv: string;
  cardHolderName: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
}

const BacPaymentModal = ({
  isOpen,
  onCloseModal,
  reservationData,
}: PaymentModalProps) => {
  const { t } = useTranslation();
  const { ...methods } = useForm<PaymentForm>();
  const [done, setDone] = useState<Boolean | string>(false);

  const closeModal = () => {
    methods.reset();
    setDone(false);
    onCloseModal();
  };

  const onSubmit = async (data: PaymentForm) => {
    // const {
    //   address,
    //   cardCvv,
    //   cardExpirationMonth,
    //   cardExpirationYear,
    //   cardHolderName,
    //   cardNumber,
    //   city,
    //   email,
    //   firstName,
    //   lastName,
    //   state,
    //   zip,
    // } = data;

    // const newPaymentDataAdapter: BacPaymentData = {
    //   adress: address,
    //   amount: reservationData.total_all_reservation,
    //   cardExpiration: `${cardExpirationYear}/${cardExpirationMonth}`,
    //   cardholderName: cardHolderName,
    //   cardPan: cardNumber,
    //   firstName,
    //   lastName,
    //   city,
    //   state,
    //   zip,
    //   email,
    //   cardCvv,
    //   reservationData,
    // };

    // try {
    //   const { RedirectData } = (await createPayment(
    //     newPaymentDataAdapter
    //   )) as BacPaymentResponse;
    //   setDone(RedirectData);
    // } catch (error) {
    //   return null;
    // }
  };

  return (
    <NcModal
      isOpenProp={isOpen}
      onCloseModal={closeModal}
      modalTitle={t("paymentModal-title")}
      contentPaddingClass="py-8 px-12"
    >
      {!done ? (
        <FormProvider {...methods}>
          <form
            className="flex flex-col gap-4"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <section>
              <header className="mb-4">
                <h3 className="text-xl font-bold underline">
                  {t("paymentModal-personalInfo")}
                </h3>
              </header>

              <div className="flex flex-col gap-2">
                {/* FIRST AND LAST NAMES */}
                <div className="flex w-full gap-2">
                  <FormControl
                    className="w-full"
                    label={t("firstName")}
                    id="payment-modal-firstName"
                    name="firstName"
                    registerOptions={{
                      required: requiredRule(t("requiredField")),
                    }}
                  >
                    {(field: any) => (
                      <Input className="w-full" placeholder="John" {...field} />
                    )}
                  </FormControl>

                  <FormControl
                    className="w-full"
                    label={t("lastName")}
                    id="payment-modal-lastName"
                    name="lastName"
                    registerOptions={{
                      required: requiredRule(t("requiredField")),
                    }}
                  >
                    {(field: any) => (
                      <Input className="w-full" placeholder="Doe" {...field} />
                    )}
                  </FormControl>
                </div>

                {/* EMAIL */}
                <FormControl
                  id="payment-modal-email"
                  name="email"
                  label={t("email")}
                  registerOptions={{
                    required: requiredRule(t("requiredField")),
                    pattern: patternRule(
                      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      t("invalidEmail")
                    ),
                  }}
                >
                  {(field: any) => (
                    <Input placeholder="johndoe@test.com" {...field} />
                  )}
                </FormControl>

                <div className="flex w-full gap-2">
                  <FormControl
                    label={t("city")}
                    className="w-1/3"
                    id="payment-modal-city"
                    name="city"
                    registerOptions={{
                      required: requiredRule(t("requiredField")),
                    }}
                  >
                    {(field: any) => (
                      <Input placeholder="New York" {...field} />
                    )}
                  </FormControl>

                  <FormControl
                    label={t("state")}
                    className="w-1/3"
                    id="payment-modal-state"
                    name="state"
                    registerOptions={{
                      required: requiredRule(t("requiredField")),
                    }}
                  >
                    {(field: any) => <Input placeholder="MA" {...field} />}
                  </FormControl>

                  <FormControl
                    id="payment-modal-zip"
                    name="zip"
                    label={t("zipCode")}
                    className="w-1/3"
                    registerOptions={{
                      required: requiredRule(t("requiredField")),
                    }}
                  >
                    {(field: any) => (
                      <Input
                        type="number"
                        maxLength={4}
                        placeholder="XXXX"
                        {...field}
                      />
                    )}
                  </FormControl>
                </div>

                <FormControl
                  id="payment-modal-address"
                  name="address"
                  label={t("address")}
                  registerOptions={{
                    required: requiredRule(t("requiredField")),
                  }}
                >
                  {(field: any) => (
                    <Textarea
                      className="resize-none p-4"
                      placeholder="New York, st. 4, house #12."
                      {...field}
                    />
                  )}
                </FormControl>
              </div>
            </section>

            <section>
              <header className="mb-4">
                <h3 className="text-xl font-bold underline">
                  {t("paymentModal-cardInfo")}
                </h3>
              </header>

              <div className="flex flex-col gap-2">
                <div className="w-full flex gap-2">
                  <FormControl
                    className="w-2/3"
                    id="payment-modal-cardNumber"
                    name="cardNumber"
                    label={t("paymentModal-cardNumber")}
                    registerOptions={{
                      required: requiredRule(t("requiredField")),
                      pattern: patternRule(
                        /^[0-9]{15,16}$/,
                        t("invalidCardNumber")
                      ),
                    }}
                  >
                    {(field: any) => (
                      <Input
                        type="number"
                        maxLength={12}
                        placeholder="XXXX XXXX XXXX XXXX"
                        {...field}
                      />
                    )}
                  </FormControl>

                  <FormControl
                    id="payment-modal-cardCvv"
                    name="cardCvv"
                    className="w-1/3"
                    label="CVV"
                    registerOptions={{
                      required: requiredRule(t("requiredField")),
                    }}
                  >
                    {(field: any) => (
                      <Input
                        type="number"
                        maxLength={3}
                        placeholder="XXX"
                        {...field}
                      />
                    )}
                  </FormControl>
                </div>

                <div className="w-full flex gap-2 items-end">
                  <FormControl
                    id="payment-modal-cardHolderName"
                    name="cardHolderName"
                    label={t("paymentModal-cardHolderName")}
                    className="w-2/3"
                    registerOptions={{
                      required: requiredRule(t("requiredField")),
                    }}
                  >
                    {(field: any) => (
                      <Input placeholder="John Doe" {...field} />
                    )}
                  </FormControl>

                  <div className="w-1/3 flex flex-col">
                    <h6>{t("paymentModal-cardExpiration")}</h6>
                    <div className="flex gap-2">
                      <FormControl
                        id="payment-modal-cardExpiration"
                        name="cardExpirationMonth"
                        className="w-1/2"
                        registerOptions={{
                          required: requiredRule(t("requiredField")),
                        }}
                      >
                        {(field: any) => (
                          <Input
                            type="number"
                            maxLength={4}
                            placeholder={t("month")}
                            {...field}
                          />
                        )}
                      </FormControl>

                      <FormControl
                        id="payment-modal-cardExpiration"
                        name="cardExpirationYear"
                        className="w-1/2"
                        registerOptions={{
                          required: requiredRule(t("requiredField")),
                        }}
                      >
                        {(field: any) => (
                          <Input
                            type="number"
                            maxLength={4}
                            placeholder={t("year")}
                            {...field}
                          />
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <ButtonPrimary>{t("continue")}</ButtonPrimary>
          </form>
        </FormProvider>
      ) : (
        <iframe width={"100%"} title="doc" srcDoc={done as string} />
      )}
    </NcModal>
  );
};

export type { PaymentModalProps };
export default BacPaymentModal;

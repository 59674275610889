import React from "react";

interface FullScreenLoaderProps {
  isLoading: boolean;
}

const FullScreenLoaderComponent: React.FC<FullScreenLoaderProps> = ({
  isLoading
}) => {

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              width: 100,
              height: 100,
              borderRadius: "50%",
              border: "5px solid #fff",
              borderTopColor: "transparent",
              animation: "spin 1s infinite linear",
            }}
          />
        </div>
      )}
    </>
  );
};

export default FullScreenLoaderComponent;

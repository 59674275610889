import { Dictionary } from '@reduxjs/toolkit';
import {useEffect, useState} from 'react';
import { useAppSelector } from 'store/hooks/useAppStore';
import { Item } from 'types/Item/Item';


export default function TourSelector(){

    const [entities, setEntities] = useState<any>([]);
    let entities_: Dictionary<Item> = useAppSelector((state) => 
        state.resorts.entities 
    );
    useEffect(() => {
        setEntities(Object.values(entities_));
    }, [entities_]);


    //Obtener de la ruta el parametro que se paso via get (?id_item=123)
    const id_item = window.location.search.split("=")[1];
    //Obtener la ruta
    const path = window.location.pathname;
    const changeEntity = (e: any) => {
        const value = e.target.value;
        window.location.href = `${path}?id_item=${value}`;
      }
    

    return(
        <h2 className="text-2xl font-semibold">
            <label htmlFor="">Tour</label>
            <select
            name=""
            id=""
            onChange={(e) => changeEntity(e)}
            className="w-full rounded-md p-2"
            value={id_item}
            >
            {entities &&
                entities.map((item: any) => (
                <option key={item.id_item} value={item.id_item}>
                    {item.detail_item}
                </option>
                ))}
            {/* <option value="">{currentItem?.detail_item ?? "Tortuga Island One Day Tour"}</option> */}
            </select>
        </h2>
    )
}
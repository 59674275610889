import { useTranslation } from "react-i18next";

const ShouldKnowSection = () => {
  const { t } = useTranslation();
  return (
    <div className="listingSection__wrap">
      {/* HEADING */}
      <h2 className="text-2xl font-semibold">
        {t("resortDetail-shouldKnownSection-title")}
      </h2>

      {/* CONTENT */}
      <div>
        <h4 className="text-lg font-semibold">
          {t("resortDetail-shouldKnownSection-cancellationPolicy-title")}
        </h4>
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
          {t("resortDetail-shouldKnownSection-cancellationPolicy-description")}
        </span>
      </div>
    </div>
  );
};

export default ShouldKnowSection;

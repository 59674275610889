import moment, {Moment} from "moment";
import { isDayInBetween } from "./isDayInBetween";
import { isWeekend } from "./isWeekend";
import { ISeason } from "types/Item/Item";

export function getSeasonPrice(calendar_date: Moment, season: ISeason, type = 'adults'): number | null {
    let {start_date, end_date, price_weekdays, price_weekends, price_weekdays_infants, price_weekends_infants, price_weekdays_children, price_weekends_children} = season;
    let formatted_start_date, formatted_end_date;

    let calendar_date_string = moment(calendar_date).format("YYYY-MM-DD");

    formatted_start_date    = moment(start_date, "YYYY-MM-DD"); 
    formatted_end_date      = moment(end_date, "YYYY-MM-DD");
    calendar_date           = moment(calendar_date_string, "YYYY-MM-DD");
    
    if(isDayInBetween(formatted_start_date, formatted_end_date, calendar_date)){
       const price_weekends_ = type === 'adults' ? price_weekends : type === 'children' ? price_weekends_children : price_weekends_infants;
       const price_weekdays_ = type === 'adults' ? price_weekdays : type === 'children' ? price_weekdays_children : price_weekdays_infants;
       return isWeekend(calendar_date) ? price_weekends_ : price_weekdays_;
    }

    return null;
}
import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCustomerState } from "hooks/useCustomerState";
import { useTranslation } from "react-i18next";
import NcDropDown from "shared/NcDropDown/NcDropDown";
import { useAppDispatch } from "store/hooks/useAppStore";
import { customerActions } from "store/slices/customerSlice";
import { useHistory } from "react-router-dom";
import AccountDropdown from "./AccountDropdown";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {

  const [customer, setCustomerData] = useState<any | null>(JSON.parse(localStorage.getItem('customer') || 'null'));

  const customerData        = useCustomerState();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const logout = async () => {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('customer');
    localStorage.removeItem('id_customer');
    setCustomerData(null);
    dispatch(customerActions.resetCustomerData());
    history.push("/")
  }

  useEffect(() => {
    setCustomerData(JSON.parse(localStorage.getItem('customer') || 'null'));
  }, [customerData]);


  return (
    <div className={`nc-MainNav1 relative z-10 notOnTop backdrop-filter`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <NcDropDown
              title={t("selectLanguage")}
              data={[
                { name: t("spanish"), id: "es" },
                { name: t("english"), id: "en" },
              ]}
              onClick={({ id }) => i18n.changeLanguage(id)}
            />
            {/* <SearchDropdown /> */}
            <div className="px-1" />
            {!!customer ? (
              <>
              <AccountDropdown username={customer.name_customer} isAgent={!(customer.id_agent == null)}/>
              <ButtonSecondary className="outline-none" onClick={logout}>Logout</ButtonSecondary>
              </>
              ) : (
              <div className="flex gap-4">
                <ButtonPrimary href="/signup">{t("auth-signup")}</ButtonPrimary>
                <ButtonSecondary className="outline-none" href="/login">
                  {t("auth-login")}
                </ButtonSecondary>
              </div>
            )}
          </div>
          <div className="flex items-center xl:hidden">
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;

import { FC, ReactNode } from "react";
import imagePng from "images/tourbooking-landing-image.png";
import LandingSearchFilters, {
  SearchTab,
} from "components/HeroSearchForm/LandingSearchForm";
import { useTranslation } from "react-i18next";
import { ImageBackground } from "./components/ImageBackground";
import { SearchBar } from "./components/SearchBar";
import { Filters } from "./components/Filters";

export interface LadingSectionProps {
  className?: string;
  listingType?: ReactNode;
  currentTab: SearchTab;
  rightImage?: string;
}

const LandingSection: FC<LadingSectionProps> = ({
  className = "",
  currentTab,
}) => {
  return (
    <div className={`flex flex-col relative ${className}`}>
      <ImageBackground />
      <SearchBar currentTab={currentTab} />
      <Filters />
    </div>
  );
};

export default LandingSection;

import { TimeRage } from "components/HeroSearchForm/FlightSearchForm";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { getReservedData } from "helpers/reservations/checkIfIsReserved";
import useWindowSize from "hooks/useWindowResize";
import moment, { Moment } from "moment";
import { useState } from "react";
import {
  DayPickerRangeController,
  DayPickerSingleDateController,
  FocusedInputShape,
} from "react-dates";
import { useTranslation } from "react-i18next";
import { IReservationCounter, Item } from "types/Item/Item";

interface ICheckInSectionProps {
  date: Moment;
  setDate: (date: any) => void;
  currentItem?: Item;
}

const CheckInSection = ({
  date,
  setDate,
  currentItem,
}: ICheckInSectionProps) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  return (
    <div className="listingSection__wrap overflow-hidden">
      <div>
        <h2 className="text-2xl font-semibold">{t("availability")}</h2>
      </div>
      <div className="listingSection__wrap__DayPickerRangeController flow-root">
        <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
          <DayPickerSingleDateController
            renderDayContents={(day, modifiers) => {
              return (
                <div
                  className={
                    modifiers.has("blocked-calendar")
                      ? "text-red-600 font-bold"
                      : ""
                  }
                >
                  {day.date()}
                </div>
              );
            }}
            isOutsideRange={(day) => day.isBefore(moment(new Date()))}
            isDayBlocked={(day) => {
              const { IS_AVAILABLE } = getReservedData(
                currentItem,
                day
              );

              return !IS_AVAILABLE;
            }}
            date={date}
            onDateChange={(_date) => setDate(_date)}
            focused={true}
            onFocusChange={(focusedInput) =>
              setFocusedInputSectionCheckDate("startDate")
            }
            initialVisibleMonth={null}
            numberOfMonths={windowSize.width < 1280 ? 1 : 2}
            daySize={getDaySize()}
            hideKeyboardShortcutsPanel
          />
        </div>
      </div>
    </div>
  );
};

export default CheckInSection;

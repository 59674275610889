import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAmenities } from "api/amenitiesApi";
import { API_STATUSES } from "api/types";
import { IAmenity } from "types/Amenity";

interface IAmenitiesState {
  error: boolean,
  fetchAmenitiesStatus: API_STATUSES,
  amenities: IAmenity[]
}

export const fetchAmenities = createAsyncThunk('amenities/fetchAmenities', getAmenities)

const initialState: IAmenitiesState = {
  error: false,
  fetchAmenitiesStatus: "IDLE",
  amenities: []
}

export const amenititesSlice = createSlice({
  name: 'amenities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAmenities.pending, (state, action) => {
      state.fetchAmenitiesStatus = 'PENDING'
    })

    builder.addCase(fetchAmenities.fulfilled, (state, action) => {
      state.fetchAmenitiesStatus = 'DONE'
      state.amenities = action.payload.amenities
    })

    builder.addCase(fetchAmenities.rejected, (state, action) => {
      state.error = true
      state.fetchAmenitiesStatus = 'ERROR'
    })
  }
})

const amenitiesReducer = amenititesSlice.reducer

export { amenitiesReducer } 
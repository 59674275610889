
import { useState } from "react";

export function useMultistepForm(steps: number){
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    function next(){
      setCurrentStepIndex(prevIndex => {
        if(prevIndex >= steps) return prevIndex;
        return prevIndex + 1
      });
    }
  
    function back(){
      setCurrentStepIndex(prevIndex => {
        if(prevIndex <= 0) return prevIndex;
        return prevIndex - 1;
      });
    }
  
    function goto(index: number){
      setCurrentStepIndex(index);
    }

    return {currentStepIndex, next, back, goto}
}
import { useResortsState } from "hooks/useResortsState";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks/useAppStore";
import { fetchItems } from "store/slices/resortsSlice";

export const useLoadListingResortPage = () => {
  const { fetchItemsStatus } = useResortsState()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (fetchItemsStatus === 'IDLE') {
      dispatch(fetchItems())
    }
  }, [])
}
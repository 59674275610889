import { createContext, Dispatch, SetStateAction, useState } from "react";
import { ProvincesNames } from "types/Provinces/ProvincesNames";

interface SearchFiltersState {
  itemType?: string;
  pricesRange: [number, number];
  location: ProvincesNames;
}

interface SearchCarsContextState {
  searchInitReservationDate: any;
  setSearchInitReservationDate: any;
  searchEndReservationDate: any;
  setSearchEndReservationDate: any;
  searchFilters: SearchFiltersState;
  setSearchFilters: Dispatch<SetStateAction<SearchFiltersState>>;
}

const SearchCarsContext = createContext<SearchCarsContextState | null>(null);

interface SearchCarsProviderProps {
  children: JSX.Element | JSX.Element[];
}

const SearchCarsProvider = ({ children }: SearchCarsProviderProps) => {
  const [searchInitReservationDate, setSearchInitReservationDate] = useState(
    new Date()
  );
  const [searchEndReservationDate, setSearchEndReservationDate] = useState();

  const [searchFilters, setSearchFilters] = useState<SearchFiltersState>({
    itemType: "",
    pricesRange: [0, 3000],
    location: "san jose",
  });

  return (
    <SearchCarsContext.Provider
      value={{
        searchInitReservationDate,
        searchEndReservationDate,
        setSearchInitReservationDate,
        setSearchEndReservationDate,
        searchFilters,
        setSearchFilters,
      }}
    >
      {children}
    </SearchCarsContext.Provider>
  );
};

export { SearchCarsProvider, SearchCarsContext };

export const ES_TRANSLATION = {
  "navigation-home": "Inicio",
  "navigation-map": "Ver mapa",
  "auth-signup": "Registrarse",
  "auth-signup-hasAccount": "¿Ya posees una cuenta?",
  "auth-login": "Iniciar sesión",
  "auth-login-notAccountYet": "¿No tienes una cuenta aún?",
  "auth-login-createAccount": "Crear una cuenta",
  "landing-title": "Busca tu tour soñado",
  "landing-subtitle": "Más de 750 resorts diferentes!",
  "landing-searchTab-oneDay": "Un día",
  "landing-searchTab-multipleDays": "Múltiples días",
  "landing-searchTab-permanent": "Permanente",
  "landing-searchSubTab-samePlace": "Mantenerse en el mismo lugar",
  "landing-searchSubTab-differentPlace": "Mantenerse en lugares diferentes",
  "landing-search-provincePlaceholder": "Provincia o ciudad",
  "landing-search-startDatePlaceholder": "Fecha de inicio",
  "landing-search-endDatePlaceholder": "Fecha de finalización",
  "itemList-loading-text": "Loading tours and amazing experiences!",
  "itemList-error-title": "Oops! Ha ocurrido un error...",
  "itemList-error-subtitle": "Intente más tarde",
  "itemRecommendations-title": "Nuestras recomendaciones",
  "itemRecommendations-subtitle": "Resotrs y tours destacados",
  "gridFilterSection-title": "Tours en",
  "gridHasMap-title": "Tours disponibles",
  "carDetail-showAllImages": "Mostrar todas las fotos",
  "resortDetail-conditions-description":
    "Al reservar el tour te comprometes a cumplir con las siguientes condiciones: ",
  "resortDetail-amenities-description":
    "Este tour cuenta con las siguientes amenidades: ",
  "resortDetail-insurances-description":
    "Este tour cuenta con los siguientes seguros: ",
  "resortDetail-shouldKnownSection-title": "Lo que deberías saber",
  "resortDetail-shouldKnownSection-cancellationPolicy-title":
    "Política de cancelación",
  "resortDetail-shouldKnownSection-cancellationPolicy-description":
    "Para realizar la cancelación de la reservación se deben tener en cuenta 4 días hábiles previos, asi como realizarse un descuento del 20% del monto atribuído.",

  // Auth words translation
  fullName: "Nombre completo",
  email: "Correo electrónico",
  phoneNumber: "Número telefónico",
  country: "País",
  address: "Dirección",
  password: "Contraseña",
  repeatPassword: "Repetir contraseña",
  invalidEmail: "Correo electrónico inválido",
  requiredField: "Campo requerido",
  invalidField: "Campo inválido",
  invalidComparePasswords: "Las contraseñas son diferentes",
  ContactDetails: "Detalles de contacto",
  ActivityDetails: "Detalles de actividad",
  countryOfOrigin: "Ciudad de Origen",
  SpecialRequirements: "Requerimientos Especiales",
  TourLanguage: "Lenguaje del Tour",
  PaymentDetails: "Detalles de pago",
  PromoCode: "Cupón de descuento",
  Availability: "Disponibilidad",

  // Reservations
  reservationSuccess: "Reservación exitosa",
  reservationError: "Error al reservar. Intenta más tarde",
  sucessfulPayment: "Pago exitoso",
  goBack: "Volver al inicio",

  // Payment modal
  "paymentModal-title": "Pago en proceso",
  "paymentModal-personalInfo": "Información personal",
  "paymentModal-cardInfo": "Información de tarjeta",
  "paymentModal-cardNumber": "Número de tarjeta",
  "paymentModal-cardExpiration": "Fecha de expiración",
  "paymentModal-cardHolderName": "Nombre del propietario name",
  "paymentModal-country": "País de residencia",

  // Search filters
  "no-cars-found": "0 tours encontrados",
  "searchFilterModal-title": "Filtros de búsqueda",
  "searchFilterModal-priceRange": "Rango de precios",
  "searchFilterModal-pricePerDay": "Precio por día",
  "searchFilterModal-minPrice": "Precio mínimo",
  "searchFilterModal-maxPrice": "Precio máximo",
  "searchFilterModal-workingOnMoreFilters": "Trabajando en más filtros",

  firstName: "Nombre",
  lastName: "Apellido",
  city: "Ciudad",
  state: "Estado",
  zipCode: "Código local",
  invalidCardNumber: "Tarjeta inválida",

  // Select language
  spanish: "Español",
  english: "Inglés",
  selectLanguage: "Seleccionar idioma",

  // Common words translation
  apply: "Aplicar",
  continue: "Continuar",
  car: "Auto",
  pluralCar: "Autos",
  resortWithCount: "{{ count }} tour",
  pluralResortWithCount: "{{ count }} tours",
  today: "Hoy",
  available: "Disponible",
  reserved: "Reservado",
  owner: "Dueño",
  passengers: "Pasajeros",
  day: "Día",
  pluralDay: "Días",
  share: "Compartir",
  save: "Guardar",
  conditions: "Condiciones",
  amenities: "Amenidades",
  insurances: "Seguros",
  availability: "Disponibilidad",
  review: "Reseña",
  pluralReview: "Reseñas",
  seeMore: "Ver más",
  location: "Ubicación",
  rent: "Rentar",
};

import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { t } from "i18next";
import { FormControl } from "components/FormControl";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  requiredRule,
  trimInputRule,
  patternRule,
  maxLengthRule,
} from "helpers/formRulesHelper";
import Input from "shared/Input/Input";
import { WizardStep } from "./WizardStep";
import {
  SubmitHandler,
  UseFormReturn,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import Select from "shared/Select/Select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import NcModal from "shared/NcModal/NcModal";

type Inputs = {
  fullname: string;
  identification: string;
  email: string;
  phoneNumber: string;
};

export const RenderContactDetails = ({
  active = false,
  next,
  goto,
  country = 53,
  setCountry = () => {},
  contract,
}: {
  active?: boolean;
  next: () => void;
  goto: (index: number) => void;
  country?: number;
  setCountry?: Dispatch<SetStateAction<number>>;
  contract: String;
}) => {
  const [phone, setPhone] = useState<any>("");
  const [acceptContract, setAcceptContract] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const methods = useFormContext();

  const onSubmit: SubmitHandler<FieldValues> = (data: any) => {
    if (
      phone === undefined ||
      (phone.length <= 3 && acceptContract === false) ||
      phone === undefined ||
      (phone.length <= 3 && acceptContract === true)
    ) {
      setPhone(undefined);
    }

    if (phone && acceptContract) {
      methods.setValue("phone", phone);
      methods.setValue("acceptContract", acceptContract);
      next();
    }
  };
  const changeCountry = (e: any) => {
    setCountry(e.target.value);
  };

  const handleAcceptContract = (e: any) => {
    setAcceptContract(e.target.checked);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const onChangeSomeOfName = (data: any) => {
    const fullname = ` ${data?.name ? data?.name : methods.getValues("firstName")}  ${data?.lastname ? data?.lastname : methods.getValues("lastName")}`;
    methods.setValue("adults[0].name", fullname);
  };

  useEffect(() => {
    methods.setValue("phone", phone);
  }, [phone]);

  useEffect(() => {
    const adults = methods.getValues("adults");
    if (!adults) return;
    const firstFullNameAdult = adults[0].name;
    const separateFullName  = firstFullNameAdult.split(" ");
    const finalNameAndLastName = separateFullName.filter((x:string) => {
      return x !== '' && x
    });
    const firstName = finalNameAndLastName[0];
    const lastName = finalNameAndLastName[1] ? finalNameAndLastName[1] : "No data";
    methods.setValue("firstName", firstName);
    methods.setValue("lastName", lastName);
  }, [active]);
  return (
    <WizardStep active={active}>
      {/* HEADING */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">2. {t("ContactDetails")}</h2>
        {active === false && (
          <span
            className="text-teal-800 hover:underline cursor-pointer"
            onClick={() => goto(1)}
          >
            Edit
          </span>
        )}
      </div>
      {active && (
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
            <FormControl
              id="register-customer-fullname"
              name={`firstName`}
              label={t("firstName")}
              registerOptions={{
                required: requiredRule(t("requiredField")),
                validate: trimInputRule(t("invalidField")),
              }}
            >
              {(field: any) => (
                <Input
                  onChange={(e: any) =>
                    onChangeSomeOfName({ name: e.target.value })
                  }
                  autoFocus
                  type="text"
                  placeholder="Ex: John"
                  {...field}
                />
              )}
            </FormControl>

            <FormControl
              id="register-customer-lastname"
              name={`lastName`}
              label={t("lastName")}
              registerOptions={{
                required: requiredRule(t("requiredField")),
                validate: trimInputRule(t("invalidField")),
              }}
            >
              {(field: any) => (
                <Input
                  onChange={(e: any) =>
                    onChangeSomeOfName({ lastname: e.target.value })
                  }
                  type="text"
                  placeholder="Ex: Doe"
                  {...field}
                />
              )}
            </FormControl>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
            <FormControl
              id="register-customer-email"
              name={`email`}
              label={t("email")}
              registerOptions={{
                required: requiredRule(t("requiredField")),
                validate: trimInputRule(t("invalidField")),
                pattern: patternRule(
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  t("invalidEmail")
                ),
              }}
            >
              {(field: any) => (
                <Input
                  type="text"
                  placeholder="Ex: johndoe@gmail.com"
                  {...field}
                />
              )}
            </FormControl>

            <FormControl
              id="register-customer-phone"
              name={`phone`}
              label={t("phoneNumber")}
              registerOptions={{
                required:
                  phone === undefined
                    ? requiredRule(t("requiredField"))
                    : false,
              }}
            >
              {(field: any) => (
                <PhoneInput
                  defaultCountry="CR"
                  international
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={(value) => {
                    setPhone(value);
                  }}
                />
              )}
            </FormControl>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
            <FormControl
              id="identificationNumber"
              name={`identification_number`}
              label={"Identification Number"}
              registerOptions={{
                required: requiredRule(t("requiredField")),
                validate: trimInputRule(t("invalidField")),
              }}
            >
              {(field: any) => (
                <Input type="text" placeholder="Ex: 123456789" {...field} />
              )}
            </FormControl>
            <FormControl
              id="registerCustomerCountry"
              name="registerCustomerCountry"
              label={t("paymentModal-country")}
            >
              {(field: any) => (
                <Select
                  name="registerCustomerCountry"
                  {...field}
                  defaultValue="53"
                  onChange={changeCountry}
                >
                  <option value="69">United States</option>
                  <option value="41">Canada</option>
                  <option value="154">Mexico</option>
                  <option value="53">Costa Rica</option>
                  <option value="1">Other</option>
                </Select>
              )}
            </FormControl>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-1 gap-2 mb-4">
            <FormControl
              id="anotations"
              name={`anotations`}
              label={t("Special Requirements")}
            >
              {(field: any) => (
                <Input type="text" placeholder="Ex: Anotations" {...field} />
              )}
            </FormControl>
          </div>

          <div>
            <input type="checkbox" />{" "}
            <span className="text-sm text-slate-500">
              {" "}
              Get emails with special offers, inspiration, tips, and other
              updates from Tourbooking. You can unsubscribe at any time.
            </span>
          </div>

          <div className="flex-wrap">
            <input type="checkbox" onChange={handleAcceptContract} required />

            <span className="text-sm text-slate-500 m-1">
              {" "}
              Accept terms and conditions{" "}
            </span>
            <br />
            <span
              className="text-blue-500 cursor-pointer text-sm italic m-1"
              onClick={() => setOpenModal(true)}
            >
              Show terms and conditions
            </span>
            <NcModal
              isOpenProp={openModal}
              onCloseModal={() => closeModal()}
              modalTitle={t("Terms and conditions")}
              contentPaddingClass="p-4"
              triggerText={null}
            >
              <div className="flex justify-center">
                <div className="w-full h-64 overflow-y-auto rounded-lg border border-gray-200 p-2 text-justify">
                  <span className="block text-sm text-slate-500">
                    {contract}
                  </span>
                </div>
              </div>
            </NcModal>
          </div>

          <div className="mt-4">
            <ButtonPrimary type="submit">Next</ButtonPrimary>
          </div>
        </form>
      )}
    </WizardStep>
  );
};

import { Item } from 'types/Item/Item'
import api from './api'

const API_ENDPOINT = '/items'

export interface ItemsResponse { 
  message: string,
  error: boolean,
  items?: Item[],
}

const getItems = async () => (await api.get<ItemsResponse>(API_ENDPOINT)).data

export {
  getItems
}
import { useMemo, useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { TimeRage } from "components/HeroSearchForm/RentalCarSearchForm";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { useQueryParams } from "hooks/useQueryParams";
import { useAppSelector } from "store/hooks/useAppStore";
import { resortsSelector } from "store/slices/resortsSlice";
import {
  amenitieAssignment,
  conditionAssignment,
  insuranceAssignment,
  IReservationCounter,
  Item,
} from "types/Item/Item";
import NcImage from "shared/NcImage/NcImage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalPhotos from "./ModalPhotos";
import { OverviewSection } from "./components/OverviewSection";
import { AmenitiesSection } from "./components/AmenitiesSection";
import { CheckInSection } from "./components/CheckInSection";
import { RatingSection } from "./components/RatingSection";
import { LocationSection } from "./components/LocationSection";
import { ShouldKnowSection } from "./components/ShouldKnowSection";
import { SidebarDetailSection } from "./components/SidebarDetailSection";
import { SidebarPriceSection } from "./components/SidebarPriceSection";
import { StickyFooterMobile } from "./components/StickyFooterMobile";
import { RecommendationsSection } from "./components/RecommendationsSection";
import { useCustomerState } from "hooks/useCustomerState";
import { IReservation } from "types/Reservation";
import { useTranslation } from "react-i18next";
import { ConditionsSection } from "./components/ConditionsSection.tsx";
import { InsurancesSection } from "./components/InsurancesSection";
import { BacPaymentModal } from "components/BacPaymentModal";
import { usePaymentHandlers } from "./hooks/usePaymentHandlers";
import { useLoadListingResortPage } from "containers/ListingResortsPages/hooks/useLoadListingResortPage";
import { env } from "env";
import { getReservedData } from "helpers/reservations/checkIfIsReserved";
import {useLocation} from "react-router-dom";

const ListingResortDetailPage = () => {
  useLoadListingResortPage();
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const { customer } = useCustomerState();
  const currentItemId = Number(queryParams.get("id_item"));
  const targetDate = queryParams.get("target_date") as string;

  const currentItem: Item | undefined = useAppSelector((state) =>
    resortsSelector.selectById(state, currentItemId)
  );

  const totalPassengers = currentItem?.reservations
  .filter(({passengers_count}) => passengers_count != undefined)
  .reduce((acc, {passengers_count = 1}) => acc + passengers_count, 0);

  const itemImages = currentItem?.images?.map(
    (_image) => `${env}/${_image.url_item_image}`
  ) as string[];

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [isOpenBacPaymentModal, setIsOpenBacPaymentModal] = useState(false);

  const [singleDate, setSingleDate] = useState<Moment>(
    moment(new Date(targetDate))
  );

  const [selectedConditions, setSelectedConditions] = useState<
    conditionAssignment[]
  >([]);
  const [selectedAmenities, setSelectedAmenities] = useState<
    amenitieAssignment[]
  >([]);
  const [selectedInsurances, setSelectedInsurances] = useState<
    insuranceAssignment[]
  >([]);

  useEffect(() => {
    setSelectedConditions(
      currentItem?.condition_assignments.filter(
        ({ IS_REQUIRED }) => IS_REQUIRED
      ) ?? []
    );

    setSelectedAmenities(
      currentItem?.amenitie_assignments.filter(
        ({ IS_REQUIRED }) => IS_REQUIRED
      ) ?? []
    );

    setSelectedInsurances(
      currentItem?.insurance_assignments.filter(
        ({ IS_REQUIRED }) => IS_REQUIRED
      ) ?? []
    );
  }, [currentItem]);

  const isReserved = useMemo(() => {
    const { IS_AVAILABLE } = getReservedData(
      currentItem,
      singleDate
    );

    return !IS_AVAILABLE;
  }, [singleDate]);

  const calculatePrice = useMemo(() => {
    const conditionsTotal = selectedConditions
      ?.map((condition) => condition.price)
      .reduce((prev, acc) => prev + acc, 0);

    const amenitiesTotal = selectedAmenities
      ?.map((amenitie) => amenitie.price)
      .reduce((prev, acc) => prev + acc, 0);

    const insurancesTotal = selectedInsurances
      ?.map((insurance) => insurance.price)
      .reduce((prev, acc) => prev + acc, 0);

    return (
      currentItem?.amount_item ??
      0 +
        Number(conditionsTotal) +
        Number(amenitiesTotal) +
        Number(insurancesTotal)
    );
  }, [
    currentItem?.amount_item,
    selectedAmenities,
    selectedConditions,
    selectedInsurances,
  ]);

  const reservationData: IReservation = useMemo(
    () => ({
      id_customer: customer?.id_customer as number,
      id_item: currentItemId,
      number_reservation: Math.round(Math.random() * 1000000),
      date_reservation: moment(new Date()).format("YYYY-MM-DD"),
      init_reservation: singleDate.format("YYYY-MM-DD"),
      end_reservation: singleDate.add(1, "days").format("YYYY-MM-DD"),
      subtotal_reservation: calculatePrice,
      total_reservation: calculatePrice,
      taxes_reservation: 0,
      total_all_reservation: calculatePrice,
      discount_reservation: 0,
      id_reservation_status: 0,
      amenities: selectedAmenities,
      conditions: selectedConditions,
      insurances: selectedInsurances,
    }),
    [customer, currentItemId, singleDate, calculatePrice]
  );

  const paymentHandlerAction = usePaymentHandlers(
    {
      openBacPaymentModal: () => setIsOpenBacPaymentModal(true),
      stripePaymentData: {
        description: `${currentItem?.detail_item}`,
        amount: calculatePrice * 100, // Total amount must be multiplied by 100 for correct floating parsing in STRIPE payment
        urlCancel: window.location.href,
        urlSuccess: "http://localhost:3000/stripe/sucessful-payment",
      },
    },
    reservationData
  );

  const handleSubmitReservation = async () => {
    if (!customer) {
      return null;
    }

    try {
      paymentHandlerAction();

      // return toast.success(t("reservationSuccess"), {
      //   position: "bottom-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } catch (error) {
      return toast.error(t("reservationError"), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  return (
    <div className="nc-ListingCarDetailPage" data-nc-id="ListingCarDetailPage">
      <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-4 gap-1 h-96 sm:gap-2">
          <div
            className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={() => handleOpenModal(0)}
          >
            <NcImage
              containerClassName="absolute inset-0"
              className="object-cover w-full h-full rounded-md sm:rounded-xl"
              src={itemImages?.[0]}
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {/*  */}
          <div
            className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={() => handleOpenModal(1)}
          >
            <NcImage
              containerClassName="absolute inset-0"
              className="object-cover w-full h-full rounded-md sm:rounded-xl"
              src={itemImages?.[1]}
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

          {/*  */}
          {itemImages
            ?.filter((_, i) => i >= 2 && i < 4)
            ?.map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 2 ? "block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 2)}
                />
              </div>
            ))}

          <div
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
            onClick={() => handleOpenModal(0)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              />
            </svg>
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              {t("carDetail-showAllImages")}
            </span>
          </div>
        </div>
      </header>
      {/* MODAL PHOTOS */}

      <ModalPhotos
        imgs={itemImages ?? []}
        isOpen={isOpen}
        onClose={handleCloseModal}
        initFocus={openFocusIndex}
        uniqueClassName="nc-ListingCarDetailPage__modalPhotos"
      />

      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* PAGE SECTIONS */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          <CheckInSection
            date={singleDate}
            setDate={setSingleDate}
            currentItem={currentItem}
          />
          {/* <OwnerSection /> */}
          {!isReserved && (
            <>
              <OverviewSection currentItem={currentItem} />
              <AmenitiesSection
                currentItem={currentItem}
                selectedAmenities={selectedAmenities}
                setSelectedAmenities={setSelectedAmenities}
              />
              <ConditionsSection
                currentItem={currentItem}
                selectedConditions={selectedConditions}
                setSelectedConditions={setSelectedConditions}
              />
              <InsurancesSection
                currentItem={currentItem}
                selectedInsurances={selectedInsurances}
                setSelectedInsurances={setSelectedInsurances}
              />
              <RatingSection />
              <LocationSection />
              <ShouldKnowSection />
            </>
          )}

          <div className="block lg:hidden">
            <SidebarDetailSection />
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-24">
            <SidebarPriceSection
              reservationData={reservationData}
              isReserved={isReserved}
              seatsTaken={totalPassengers}
              // dateRangeValue={singleDate.subtract(1, "day")}
              dateRangeValue={singleDate.subtract(1, "day")}
              setDateRangeValue={setSingleDate}
              currentItem={currentItem}
              totalPrice={calculatePrice}
              differenceDays={1}
              handleSubmitReservation={handleSubmitReservation}
            />
          </div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobile totalPrice={calculatePrice} />

      {/* OTHER SECTION */}
      <RecommendationsSection />

      <BacPaymentModal
        isOpen={isOpenBacPaymentModal}
        onCloseModal={() => setIsOpenBacPaymentModal(false)}
        reservationData={reservationData}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ListingResortDetailPage;

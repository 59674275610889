import { useSearchCarsContext } from "containers/ListingResortsPages/hooks/useSearchCarsContext";
import { useItemTypesState } from "hooks/useItemTypesState";
import { ItemType } from "types/Item/ItemType";
import ItemTypeButton from "./ItemTypeButton";

export interface SearchFiltersIcon extends ItemType {
  srcIcon: string;
}

const ItemTypeNav = () => {
  const { itemTypes } = useItemTypesState();
  const { searchFilters, setSearchFilters } = useSearchCarsContext();

  const selectItemType = (itemType: string) =>
    setSearchFilters((prev) => ({
      ...prev,
      itemType: itemType === prev.itemType ? undefined : itemType,
    }));

  const formattedItems: SearchFiltersIcon[] = itemTypes.map((item) => ({
    ...item,
    srcIcon: `icons/searchFilters/${item.id_item_type}.png`,
  }));

  return (
    <nav className="w-full pt-4 flex gap-12 justify-center">
      {formattedItems.map((filterItem) => (
        <ItemTypeButton
          itemData={filterItem}
          onSelect={selectItemType}
          isSelected={searchFilters.itemType === filterItem.detail_item_type}
          key={filterItem.id_item_type}
        />
      ))}
    </nav>
  );
};
export default ItemTypeNav;

import moment from "moment";

export const PHOTOS: string[] = [
    "https://www.filepicker.io/api/file/yF0uEMlVTvKinbvD27Yt",
    "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  ];
  
 export const reservationDataDummy = {
    id_customer: 1,
    id_item: 5,
    number_reservation: Math.round(Math.random() * 1000000),
    date_reservation: moment(new Date()).format("YYYY-MM-DD"),
    init_reservation: moment(new Date()).format("YYYY-MM-DD"),
    end_reservation: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
    subtotal_reservation: 120 * 100,
    total_reservation: 120 * 100,
    taxes_reservation: 0,
    total_all_reservation: 120 * 100,
    discount_reservation: 0,
    id_reservation_status: 0,
    amenities: [],
    conditions: [],
    insurances: [],
  }
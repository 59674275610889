import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";

export interface GuestsInputNoPopupProps {
  defaultValue: {
    guestAdults?: number;
    guestChildren?: number;
    guestInfants?: number;
  };
  onChange?: (data: GuestsInputNoPopupProps["defaultValue"]) => void;
  fieldClassName?: string;
}

const GuestsInputNoPopup: FC<GuestsInputNoPopupProps> = ({
  defaultValue,
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue.guestAdults || 0
  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    defaultValue.guestChildren || 0
  );
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
    defaultValue.guestInfants || 0
  );

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue.guestAdults || 0);
    setGuestChildrenInputValue(defaultValue.guestChildren || 0);
    setGuestInfantsInputValue(defaultValue.guestInfants || 0);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      onChange({
        guestAdults: guestAdultsInputValue,
        guestChildren: guestChildrenInputValue,
        guestInfants: guestInfantsInputValue,
      });
    }
  }, [guestAdultsInputValue, guestChildrenInputValue, guestInfantsInputValue]);

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  return (
   <>
    <NcInputNumber
    className="w-full"
    defaultValue={guestAdultsInputValue}
    onChange={(value) => setGuestAdultsInputValue(value)}
    max={10}
    min={1}
    label="Adults"
    desc="Ages 13 or above"
    />
    
    <NcInputNumber
    className="w-full mt-6"
    defaultValue={guestChildrenInputValue}
    onChange={(value) => setGuestChildrenInputValue(value)}
    max={4}
    label="Children"
    desc="Ages 2–12"
    />

    <NcInputNumber
    className="w-full mt-6"
    defaultValue={guestInfantsInputValue}
    onChange={(value) => setGuestInfantsInputValue(value)}
    max={4}
    label="Infants"
    desc="Ages 0–2"
    />
   </>
  );
};

export default GuestsInputNoPopup;

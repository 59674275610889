import { FC, useState, useMemo, useEffect } from "react";
import moment, { Moment } from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { TourModalPageProps } from "./types/TourModalPageProps";
import { RenderContactDetails } from "./components/RenderContactDetails";
import { RenderSectionCheckInDate } from "./components/RenderSectionCheckInDate";
import { PaymentInformationForm } from "./components/PaymentInformationForm";
import { RenderSidebar } from "./components/RenderSidebar";
import { useMultistepForm } from "./hooks/useMultistepForm";
import { Guests } from "./types/Guests";
import { useAppSelector } from "store/hooks/useAppStore";
import { resortsSelector } from "store/slices/resortsSlice";
import { useQueryParams } from "hooks/useQueryParams";
import { Item } from "types/Item/Item";
import { useLoadListingResortPage } from "containers/ListingResortsPages/hooks/useLoadListingResortPage";
import api from "api/api";
import { ReservationResponse } from "./types/ReservationRespose";
import SuccessIconURL from "../../images/success-icon.png";
import Timer from "./components/Timer";
import { env } from "env";
import { getTourDayPrice } from "helpers/reservations/calculatePrices";
import TourSelector from "./components/TourSelector";
import FullScreenLoaderComponent from "components/FullScreenLoaderComponent";

const TourModalPage: FC<TourModalPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  // GET RESORTS FIRST
  useLoadListingResortPage();

  let history = useHistory();

  const queryParams = useQueryParams();

  const currentItemId = Number(queryParams.get("id_item"));

  const currentItem: Item | undefined = useAppSelector((state) =>
    resortsSelector.selectById(state, currentItemId)
  );

  const methods = useForm();
  const { currentStepIndex, next, goto } = useMultistepForm(3);
  const [submitPayment, setSubmitPayment] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState("");
  const [isValidDiscountCode, setIsValidDiscountCode] = useState<number>(0);
  const [country, setCountry] = useState<number>(53);
  const [reservation, setReservation] = useState<ReservationResponse>();
  const [registerStatus, setResgisterStatus] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [scheduleSelected, setScheduleSelected] = useState<String>("");
  const [currentGuests, setCurrentGuests] = useState<Guests>({
    guestAdults: 1,
    guestChildren: 0,
    guestInfants: 0,
  });
  const [customer, setCustomerData] = useState<any | null>(
    JSON.parse(localStorage.getItem("customer") || "null")
  );
  const [contract, setContract] = useState<String>("");

  const domain = env;
  const currentTime = moment();

  const subtotal = useMemo(() => {
    if (
      currentItem != undefined &&
      currentGuests.guestAdults !== undefined &&
      currentGuests.guestChildren !== undefined
    ) {
      const selectedDate_ = selectedDate ? selectedDate : moment();
      const amount_item_adults =
        getTourDayPrice(selectedDate_, currentItem, "adults") || 0;
      const amount_item_children =
        getTourDayPrice(selectedDate_, currentItem, "children") || 0;
      const amount_item_infants =
        getTourDayPrice(selectedDate_, currentItem, "infants") || 0;
      const sbutotal_ =
        amount_item_adults * currentGuests.guestAdults +
        amount_item_children * currentGuests.guestChildren +
        amount_item_infants * (currentGuests.guestInfants || 0);

      return isValidDiscountCode
        ? sbutotal_ * (1 - isValidDiscountCode / 100)
        : sbutotal_;
    }
  }, [currentGuests, currentItem, isValidDiscountCode, selectedDate]);

  const taxes = useMemo(() => {
    if (subtotal != undefined) {
      return subtotal * 0.13;
    }
  }, [subtotal, currentItem]);

  const total = useMemo(() => {
    if (subtotal != undefined && taxes != undefined) {
      return subtotal + taxes;
    }
  }, [subtotal, taxes, currentItem]);

  const [isLoading, setIsLoading] = useState(false);

  const verifyDate = useMemo(() => {
    if (
      currentItem?.entry_time_item &&
      moment(currentTime, "hmm").format("HH:mm") >
        moment(currentItem?.entry_time_item.replace(/:/g, ""), "hmm").format(
          "HH:mm"
        )
    ) {
      setSelectedDate(moment().add(1, "days"));
    } else {
      setSelectedDate(moment());
    }
  }, [currentItem]);

  const createReservation = async (): Promise<ReservationResponse> => {
    setIsLoading(true);
    const { registerCustomerCountry } = methods.getValues();
    const body = {
      contact: {
        id_pais: registerCustomerCountry,
        id_customer_type: 1,
        firstname: methods.getValues("firstName"),
        lastname: methods.getValues("lastName"),
        email: methods.getValues("email"),
        phone: methods.getValues("phone"),
        identification_number: methods.getValues("identification_number"),
      },
      passengersInfo: {
        adults: methods.getValues("adults"),
        children: methods.getValues("children"),
        infants: methods.getValues("infants"),
      },
      pickedLocation,
      date: selectedDate,
      id_item: currentItemId == 0 ? 1 : currentItemId,
      description: currentItem?.detail_item,
      guests: currentGuests,
      reservationNumber: Math.round(Math.random() * 1000000),
      total: total ?? currentItem?.amount_item,
      id_agent: customer?.id_agent || 0,
      discount: isValidDiscountCode,
      anotations: methods.getValues("anotations"),
      schedule_selected: scheduleSelected,
    };

    console.log("body", body);

    const response = await api.post("/createReservation", body).finally(() => {
      setIsLoading(false);
    });

    const reservation = response.data as ReservationResponse;
    if (!reservation.error) {
      setReservation(reservation);
    }
    return reservation;
  };

  const getContractInfo = async () => {
    const response = await api.get("/contract");
    const contractInfo: any = response.data;
    const contractText = contractInfo?.contractTemplate?.contract_text || "";
    setContract(contractText);
  };

  const back = () => {
    window.location.reload();
  };

  const [pickedLocation, setPickedLocation] = useState<String>("");

  useEffect(() => {
    getContractInfo();

  }, []);

  return (
    <div
      className={`nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      <FormProvider {...methods}>
        <main
          className={`container relative z-10 my-11 grid grid-cols-1 ${
            registerStatus === 0 ? "lg:grid-cols-3" : "lg:grid-cols-1"
          }`}
        >
          <div className="w-full mb-10 space-y-8 lg:space-y-10 lg:pr-10 md:col-span-2 order-2 md:order-1">
            {registerStatus === 0 ? (
              <>
                <div className="render_movil">
                  <Timer
                    seconds={20 * 60}
                    currentStepIndex={currentStepIndex}
                  />
                </div>
                <div className="render_movil">
                  <TourSelector></TourSelector>
                </div>
                <RenderSectionCheckInDate
                  currentItem={currentItem}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  active={currentStepIndex === 0}
                  next={next}
                  goto={goto}
                  currentGuests={currentGuests}
                  setCurrentGuests={setCurrentGuests}
                  setPickedLocation={setPickedLocation}
                  setScheduleSelected={setScheduleSelected}
                />
                <RenderContactDetails
                  active={currentStepIndex === 1}
                  contract={contract}
                  setCountry={setCountry}
                  next={next}
                  goto={goto}
                />
                <PaymentInformationForm
                  active={currentStepIndex === 2}
                  country={country}
                  total={Number(total)}
                  submitPayment={submitPayment}
                  setSubmitPayment={setSubmitPayment}
                  setResgisterStatus={setResgisterStatus}
                  reservation={reservation}
                  createReservation={createReservation}
                />
              </>
            ) : (
              <>
                <div
                  className={`listingSectionSidebar__wrap shadow-lg p-8 bg-slate-100`}
                >
                  <div className="flex justify-left">
                    <a
                      type="button"
                      onClick={back}
                      style={{ cursor: "pointer" }}
                      className="text-blue-700 text-sm dark:bg-blue-600 hover:text-blue-400"
                    >
                    Back
                    </a>
                  </div>
                  <h1 className="font-bold lg:text-center md:text-center">
                    Reservation created successfully
                  </h1>
                  <div className="md:grid grid-cols-2 sm:flex-row">
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <h1 className="font-bold">Reservation info</h1>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Tour: </b>
                            </td>
                            <td>
                              {reservation?.reservation?.item.detail_item}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Date: </b>
                            </td>
                            <td>
                              {(
                                reservation?.reservation?.init_reservation || ""
                              ).substring(0, 10)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <h1 className="font-bold"> Passengers</h1>
                            </td>
                          </tr>
                          {reservation?.reservation?.details.map(
                            (item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td colSpan={2}>
                                      <b>Passengers {item.type}</b>
                                    </td>
                                  </tr>

                                  {JSON.parse(item.json_passengers_info).map(
                                    (item: any) => {
                                      return <td colSpan={2}>{item.name}</td>;
                                    }
                                  )}
                                </>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                      <br />
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <h1 className="font-bold">Contact</h1>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Names: </b>
                            </td>
                            <td>
                              {reservation?.reservation?.customer.name_customer}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Email: </b>
                            </td>
                            <td>{reservation?.reservation?.customer.email}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Phone: </b>
                            </td>
                            <td>
                              {
                                reservation?.reservation?.customer
                                  .phone_customer
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <div className="flex justify-center sm:(min-width: 380px) mt-3">
                        <img src={SuccessIconURL} alt="success" width="50%" />
                      </div>
                      <p className="text-gray-500 text-center">
                        Your reservation number is:{" "}
                        <span className="font-bold">
                          {reservation?.reservation?.id_reservation}
                        </span>
                      </p>
                      <br />
                      <div className="flex justify-center">
                        <a
                          type="button"
                          href={`${domain}/api/reservations/${reservation?.reservation?.id_reservation}/pdf`}
                          target="_blank"
                          className="round text-white bg-blue-700 px-2 py-3"
                        >
                          Download PDF
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {registerStatus === 0 ? (
            <div className="order-2 md:order-1">
              <div className="w-full md:sticky top-8">
                <div className="render_pc">
                  <Timer
                    seconds={20 * 60}
                    currentStepIndex={currentStepIndex}
                  />
                </div>
                <RenderSidebar
                  currentStepIndex={currentStepIndex}
                  currentItem={currentItem}
                  guests={currentGuests}
                  selectedDate={selectedDate}
                  createReservation={createReservation}
                  submitPayment={submitPayment}
                  setSubmitPayment={setSubmitPayment}
                  total={total}
                  subtotal={subtotal}
                  taxes={taxes}
                  discountCode={discountCode}
                  setDiscountCode={setDiscountCode}
                  isValidDiscountCode={0}
                  setIsValidDiscountCode={setIsValidDiscountCode}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </main>
      </FormProvider>

      <FullScreenLoaderComponent isLoading={isLoading} />
    </div>
  );
};

export default TourModalPage;

import React, { FC } from "react";

export interface CheckboxProps {
  label?: string;
  subLabel?: string;
  className?: string;
  name: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const ButtonCheckbox: FC<CheckboxProps> = ({
  subLabel = "",
  label = "",
  name,
  className = "",
  defaultChecked,
  onChange,
  disabled,
  checked
}) => {
  return (
    <div className={`flex text-sm sm:text-base items-center ${className}`}>
      <input
        id={name}
        name={name}
        type="checkbox"
        className="peer hidden"
        onChange={(e) => onChange && onChange(e.target.checked)}
        disabled={disabled}
        defaultChecked={defaultChecked}
        checked={checked}
      />
      <label htmlFor={name} className="select-none cursor-pointer rounded-lg 
       py-3 px-6 font-bold ttnc-ButtonPrimary disabled:bg-opacity-50 disabled:pointer-events-none bg-primary-6000 hover:bg-primary-700  text-neutral-50 transition-colors duration-200 ease-in-out peer-checked:bg-primary-400 peer-checked:text-secondary-200"> {label} </label>

    </div>
  );
};

export default ButtonCheckbox;
import { Moment } from "moment";
import { SingleDatePicker } from "react-dates";
import { useTranslation } from "react-i18next";

interface RentalCarsDatePickerProps {
  date: Moment;
  handleChange: (date: any) => void;
  disabled?: boolean;
}
const RentalCarsDatePicker = ({
  date,
  disabled,
  handleChange,
}: RentalCarsDatePickerProps) => {
  const { t } = useTranslation();

  const renderInputpickUpDate = () => {
    return (
      <div
        className={`flex flex-1 relative py-4 px-6 flex-shrink-0 items-center space-x-3`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow flex-shrink-0">
          <div className="absolute inset-0" />

          <div className="inline-flex items-center text-base xl:text-lg font-semibold">
            <span className="flex-shrink-0">
              {date
                ? date.format("DD MMM")
                : t("landing-search-startDatePlaceholder")}
            </span>
          </div>

          <span className="block mt-1 text-sm text-neutral-400 font-light leading-none">
            {date ? t("landing-search-startDatePlaceholder") : `Add date`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`RentalCarDatesRangeInput relative flex-shrink-0 flex nc-flex-2-auto z-10`}
    >
      <div className="absolute inset-0 flex">
        <SingleDatePicker
          numberOfMonths={1}
          date={date}
          focused={false}
          id="date"
          onDateChange={handleChange}
          onFocusChange={({ focused }) => {}}
          orientation={"horizontal"}
          noBorder
          hideKeyboardShortcutsPanel
          anchorDirection="left"
        />
      </div>
      <div>{renderInputpickUpDate()}</div>
    </div>
  );
};
export default RentalCarsDatePicker;

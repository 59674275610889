import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { t } from "i18next";
import { FormControl } from "components/FormControl";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { requiredRule, trimInputRule } from "helpers/formRulesHelper";
import Input from "shared/Input/Input";
import moment, { Moment } from "moment";
import GuestsInputNoPopup from "components/HeroSearchForm/GuestsInputNotPopup";
import { DayPickerSingleDateController } from "react-dates";
import Checkbox from "shared/Checkbox/Checkbox";
import Select from "shared/Select/Select";
import useWindowSize from "hooks/useWindowResize";
import { Guests } from "../types/Guests";
import { PassengerInputProps } from "../types/PassengerInputProps";
import { WizardStep } from "./WizardStep";
import { useFormContext, useFieldArray, useForm } from "react-hook-form";
import {
  amenitieAssignment,
  conditionAssignment,
  insuranceAssignment,
  IReservationCounter,
  ISpecialToursDay,
  Item,
} from "types/Item/Item";
import { IReservation } from "types/Reservation";
import { isDayInBetween } from "../utils/isDayInBetween";
import { isWeekend } from "../utils/isWeekend";
import { getSeasonPrice } from "../utils/getSeasonPrice";
import { isToday } from "../utils/isToday";
import { getSpecialDayPrice } from "../utils/getSpecialDayPrice";
import { getTourDayPrice } from "helpers/reservations/calculatePrices";
import Legend from "./Leyend";
import { getReservedData } from "helpers/reservations/checkIfIsReserved";
import ButtonCheckbox from "components/ButtonCheckbox/ButtonCheckbox";
import api from "api/api";


export const RenderSectionCheckInDate = ({
  active = false,
  next,
  goto,
  selectedDate,
  setSelectedDate,
  currentGuests,
  setCurrentGuests,
  currentItem,
  setPickedLocation,
  setScheduleSelected,
}: {
  active?: boolean,
  selectedDate: Moment | null,
  next: () => void,
  goto: (index: number) => void,
  setSelectedDate: Dispatch<SetStateAction<moment.Moment | null>>,
  currentGuests: Guests,
  setCurrentGuests: Dispatch<SetStateAction<Guests>>,
  currentItem: Item | undefined,
  setPickedLocation: Dispatch<SetStateAction<String>>,
  setScheduleSelected: Dispatch<SetStateAction<String>>,
  
}) => {
 
  const { width } = useWindowSize();
  const currentTime = moment();
  const handleSubmit = (data: any) => next();
  const [picked, setPicked] = useState<boolean>(false);
  const [findSchedule, setIsFindShchedule] = useState<boolean>(false);
  const [checked, setChecked] = useState<string>("");
  const [schedulesByDate, setSchedulesByDate] = useState<any>([]);

  const { register, formState: { errors } } = useForm({
    defaultValues: {
      adults: [{
        name: "",
        country: ""
      }],
      children: [],
      infants: []
    }
  });

  const getSchedulesByDate = async () => {

    const response = await api.get(`/tours/find-schedules-by-date?id_item=${currentItem?.id_item}&date_apply=${selectedDate?.format("YYYY-MM-DD")}`);
    setSchedulesByDate(response.data);

  }


  const getCalendarDayColor = (calendar_day: Moment): string => {
    let color = "text-lime-500";
    calendar_day.format("YYYY-MM-DD");

    const { IS_AVAILABLE, capacity_porc }  = getReservedData(currentItem, calendar_day);
    
    if(calendar_day.isBefore(currentTime.format("YYYY-MM-DD")))
      return "text-slate-300";
     
    if(!IS_AVAILABLE)
      return "text-red-300";

    if(capacity_porc >= 50)
      return "text-yellow-500";

    return color;
  }

  const methods = useFormContext();

  const { fields: adults, append: appendAdults, remove: removeAdults} = useFieldArray({
    name: 'adults',
  })
  
  const { fields: children, append: appendChildren, remove: removeChildren } = useFieldArray({
    name: 'children',
  })

  const { fields: infants, append: appendInfants, remove: removeInfants } = useFieldArray({
    name: 'infants',
  })
  
  /*There is a better way to write this */
  useEffect(() => {
    appendAdults({ name: "", country: "" });
    getSchedulesByDate();
  }, []);

  useEffect(() => {
    if (findSchedule === true && selectedDate) {
      getSchedulesByDate();
      setIsFindShchedule(false);
    }
  },[selectedDate]);

  const handleGuestsInputChange = (guests: Guests) => {
    setCurrentGuests(prevGuests => {
      if (prevGuests.guestAdults && guests.guestAdults) {
        if ((guests.guestAdults - prevGuests.guestAdults) > 0) {
          appendAdults({ name: "", country: "" })
        } else {
          if ((guests.guestAdults - prevGuests.guestAdults) < 0) {
            removeAdults(prevGuests.guestAdults - 1)
          }
        }
      }

      if (prevGuests.guestChildren !== undefined && guests.guestChildren !== undefined) {
        if ((guests.guestChildren - prevGuests.guestChildren) > 0) {
          appendChildren({ name: "", country: "" })
        } else {
          if ((guests.guestChildren - prevGuests.guestChildren) < 0) {
            removeChildren(prevGuests.guestChildren - 1)
          }
        }
      }

      if (prevGuests.guestInfants !== undefined && guests.guestInfants !== undefined) {
        if ((guests.guestInfants - prevGuests.guestInfants) > 0) {
          appendInfants({ name: "", country: "" })
        } else {
          if ((guests.guestInfants - prevGuests.guestInfants) < 0) {
            removeInfants(prevGuests.guestInfants - 1)
          }
        }
      }


      return guests;
    })
  };


  return (
    <WizardStep active={active}>

      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">1. {t("Availability")}</h2>
        {active === false && (
          <span className="text-teal-800 hover:underline cursor-pointer" onClick={() => goto(0)}>Edit</span>
        )}
      </div>
    
      {active && (
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
      
          <div  className="flex justify-center items-center mr-5 mb-5 max-w-screen-mb">
            <DayPickerSingleDateController
              date={selectedDate}
              daySize={width < 500 ? 43 : 50}
              focused={true}
              initialVisibleMonth={null}
              numberOfMonths={width > 1280 ? 2 : 1}
              onDateChange={(date) => {
                setIsFindShchedule(true);
                setSelectedDate(date)
              }}
              onFocusChange={(something) => undefined}
              isDayBlocked={(day) => {
                const { IS_AVAILABLE } = getReservedData(
                  currentItem,
                  day
                );
                if(
                  moment(currentTime, "hmm").format("HH:mm") > 
                  moment(currentItem?.entry_time_item.replace(/:/g, ''), "hmm").format("HH:mm") && 
                  day.format("YYYY-MM-DD") === moment(currentTime).format("YYYY-MM-DD")
                ){
                  return true;
                }
                return !IS_AVAILABLE;
              }}
              renderDayContents={(day: Moment, modifiers) => {
                return (
                  <>
                  {
                      moment(currentTime, "hmm").format("HH:mm") > 
                      moment(currentItem?.entry_time_item.replace(/:/g, ''), "hmm").format("HH:mm") && 
                      day.format("YYYY-MM-DD") === moment(currentTime).format("YYYY-MM-DD") ? 
                      (
                        <div 
                        className="text-purple-600">
                            <span className="font-bold">
                              {day.date()}
                            </span>
                            <span className="block text-sm">
                              ${getTourDayPrice(day, currentItem)}
                            </span>
    
                      </div>
                      ) 
                    : 
                    (
                        <div 
                        className={getCalendarDayColor(day)}>
                            <span className="font-bold block">
                              {day.date()}
                            </span>
                      
                            <span className="block text-sm">
                              ${getTourDayPrice(day, currentItem)}
                            </span>
    
                      </div>
                    )
                  }
                  </>
                 
                );
              }}
              isOutsideRange={(day) => {
               if(
                moment(currentTime, "hmm").format("HH:mm") > 
                moment(currentItem?.entry_time_item.replace(/:/g, ''), "hmm").format("HH:mm") && 
                day.format("YYYY-MM-DD") <= moment(currentTime).format("YYYY-MM-DD")
               ){
                return true;
               }else {

                 return false;
               }
                // day.format("YYYY-MM-DD");
                // console.log(day.isBefore(currentTime.format("YYYY-MM-DD")));
                // return day.isBefore(currentTime.format("YYYY-MM-DD"));
              }}
              hideKeyboardShortcutsPanel
            />
          </div>
       
          <div className="mb-4">
            <h2 className="text-xl mb-3">Availables Schedules: </h2>
            
            <FormControl>
              {(field: any) => (
                <div className="flex">
                    <ButtonCheckbox
                      {...field}
                      className="mr-3"
                      name={`schedules_availables` as const}
                      label={currentItem?.entry_time_item}
                      checked={checked === currentItem?.entry_time_item ? true : false}
                      onChange={() => {
                        setChecked(String(currentItem?.entry_time_item))
                        setScheduleSelected(String(currentItem?.entry_time_item))
                      }}
                    /> 
                    {
                      currentItem?.schedules?.map((schedule, index) => {
                        if (schedulesByDate?.some((item: any) => Number(item?.item_schedule_id) === Number(schedule.id) && item.is_active === 'true')) {
                          return (
                            <ButtonCheckbox
                              {...field}
                              key={index}
                              className="mr-3"
                              name={`schedules_availables.${index}` as const}
                              label={schedule.entry_time_item}
                              checked={checked === schedule?.entry_time_item}
                              onChange={() => {
                                setChecked(String(schedule?.entry_time_item));
                                setScheduleSelected(String(schedule?.entry_time_item));
                              }}
                            />
                          );
                        }
                        return null;
                      })
                      
                    }
                </div>
              )}
            </FormControl>
          </div>

          <div className="mt-4">
            <h2 className="text-xl mb-3">Passengers Info: </h2>

            {
              adults.map((field, index) => (
                <PassengerInput type="Adult" field={field} number={index} key={field.id} name={`adults.${index}` as const} />
              ))
            }
            {
              children.map((field, index) => (
                <PassengerInput type="Children" field={field} number={index} key={field.id} name={`children.${index}` as const} />
              ))
            }
            {
              infants.map((field, index) => (
                <PassengerInput type="Infant" field={field} number={index} key={field.id} name={`infants.${index}` as const} />
              ))
            }

          </div>
            <div className="my-4">
            <h2 className="text-xl mb-3">Passengers Quantity: </h2>
            <GuestsInputNoPopup
              onChange={handleGuestsInputChange}
              defaultValue={currentGuests}
            />
          </div>
          <div>
            <div className="mb-2">
              <h3 className="text-lg font-bold mb-2">Pickup Details</h3>
              <p>The provider offers pickup.</p>
            </div>

            <FormControl
              id="register-customer-pickup"
              name="pickup"
            >
              {(field: any) => (
                <Checkbox
                  {...field}
                  label="I would like to be picked up from"
                  className="mb-4"
                  onChange={(checked) => {
                    setPicked(checked);
                  } }
                />
              )}
            </FormControl>
           {
              picked && (
                <FormControl
                id="pickup_location"
                name="pickup_location"
              >
                {(field: any) => (
                  <Input 
                    type="text"
                    placeholder="Pick up place"
                    {...field} 
                    onChange={(e) => {
                      setPickedLocation(e.target.value)
                    }}  
                  />
                )}
              </FormControl>
              )
           }
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
            <div className="">
              <FormControl
                id="register-customer-tour-langauge"
                name="tour-langauge"
                label={t("TourLanguage")}
              >
                {(field: any) => (
                  <Select name="tour-language" {...field}>
                    <option value="english">English - Guide</option>
                    <option value="spanish">Spanish - Guide</option>
                  </Select>
                )}
              </FormControl>
            </div>
          </div>
          <div className="mt-4">
            {/* SUBMIT */}
            {/* <p className="m-1">From {selectedDate?.date()}: Public tour might be operated by Costa Cat II</p> */}
            <ButtonPrimary>Next</ButtonPrimary>
          </div>
        </form>
      )}
    </WizardStep>
  );
};

// const PassengerInput = ({ name, number }: PassengerInputProps) => {
const PassengerInput = ({ name, number, type }: PassengerInputProps) => {
  return (
    <div className="mb-4">
      <p className="mb-2 text-md text-slate-800 font-semibold">
        {type} #{number + 1} Information
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <FormControl
          id={`${name}.name`}
          name={`${name}.name`}
          label={t("fullName")}
          registerOptions={{
            required: requiredRule(t("requiredField")),
            validate: trimInputRule(t("invalidField")),
          }}
        >
          {(field: any) => (
            <Input type="text" placeholder="Ex: John Doe" {...field} />
          )}
        </FormControl>
        <FormControl
          id={`${name}.country`}
          name={`${name}.country`}
          label={t("countryOfOrigin")}
          registerOptions={{
            required: requiredRule(t("requiredField")),
            validate: trimInputRule(t("invalidField")),
          }}
        >
          {(field: any) => (
            <Input type="text" placeholder="Ex: Costa Rica" {...field} />
          )}
        </FormControl>
      </div>
    </div>
  );
};

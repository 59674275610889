const PROVINCES = [
  {
    text: "San José",
    value: "san jose",
  },
  {
    text: "Alajuela",
    value: "alajuela",
  },
  {
    text: "Cartago",
    value: "cartago",
  },
  {
    text: "Heredia",
    value: "heredia",
  },
  {
    text: "Guanacaste",
    value: "guanacaste",
  },
  {
    text: "Puntarenas",
    value: "puntarenas",
  },
  {
    text: "Limón",
    value: "limon",
  },
];

export { PROVINCES };

import { configureStore } from "@reduxjs/toolkit";

// REDUCERS
import { resortsReducer } from "./slices/resortsSlice";
import { countriesReducer } from "./slices/countriesSlice";
import { amenitiesReducer } from "./slices/amenitiesSlice";
import { customerReducer } from "./slices/customerSlice";
import { itemTypesReducer } from "./slices/itemsTypeSlice";

export const store = configureStore({
  reducer: {
    resorts: resortsReducer,
    countries: countriesReducer,
    amenities: amenitiesReducer,
    customer: customerReducer,
    itemTypes: itemTypesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { Item, ISpecialToursDay } from "types/Item/Item";
import moment , {Moment} from "moment";

export function getSpecialDayPrice(calendar_date: Moment, currentItem: Item): number | null {
    let price = null;

    currentItem.special_tours_days?.find(({date: special_tour_day, price: special_tour_price}: ISpecialToursDay) => {
        let special_tour_day_moment_datetime_format = moment(new Date(special_tour_day));
        if(special_tour_day_moment_datetime_format.date() == calendar_date.date()){
            price = special_tour_price;
            return true;
        }
    });

    return price;
}
import ButtonPrimary from "shared/Button/ButtonPrimary"

interface IStickyFooterMobileProps {
  totalPrice: number,
}

function StickyFooterMobile({ totalPrice }: IStickyFooterMobileProps) {
  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
    <div className="container flex items-center justify-between">
      <span className="text-2xl font-semibold">
        ${totalPrice}
        <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
          en total
        </span>
      </span>

      <ButtonPrimary href="##">Rentar</ButtonPrimary>
    </div>
  </div>
  )
}

export default StickyFooterMobile
import { ItemType } from "types/Item/ItemType";
import api from "./api";

interface GetItemTypesResponse {
  itemTypes: ItemType[];
}

const ENDPOINT = "/data/items/types";

const getItemTypes = async () =>
  (await api.get<GetItemTypesResponse>(ENDPOINT)).data;

export { getItemTypes };

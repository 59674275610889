import AuthUser from "HOC/AuthUser/AuthUser";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { replace } = useHistory();

  const navigateBack = () => replace("/");

  return (
    <AuthUser>
      <div
        className={`nc-CheckOutPage ${className} flex flex-col items-center gap-8 justify-center my-24`}
        data-nc-id="CheckOutPage"
      >
        <h1 className="text-5xl font-bold text-primary-6000">
          {t("sucessfulPayment")}
        </h1>

        <ButtonPrimary onClick={navigateBack}>{t("goBack")}</ButtonPrimary>
      </div>
    </AuthUser>
  );
};

export default CheckOutPage;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCustomerData  } from "api/customerApi";
import { API_STATUSES } from "api/types";
import { ICustomer } from "types/Customer";

interface ICustomerState {
  error: boolean,
  fetchCustomerStatus: API_STATUSES,
  customer: ICustomer | null
}

export const fetchCustomerData = createAsyncThunk('customer/fetchCustomerData', getCustomerData)

const initialState: ICustomerState = {
  error: false,
  fetchCustomerStatus: 'IDLE',
  customer: null
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerData: (state, action) => {
      state.customer = action.payload.customer
      state.fetchCustomerStatus = 'DONE'
    },
    resetCustomerData: (state) => {
      state.customer = null
      state.fetchCustomerStatus = 'DONE'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerData.pending, (state, action) => {
      state.fetchCustomerStatus = 'PENDING'
    })

    builder.addCase(fetchCustomerData.fulfilled, (state, action) => {
      state.fetchCustomerStatus = 'DONE'
      state.customer = action.payload.customer as ICustomer
    })

    builder.addCase(fetchCustomerData.rejected, (state, action) => {
      state.error = true
      state.fetchCustomerStatus = 'ERROR'
    })
  }
})

const customerReducer = customerSlice.reducer
const customerActions = customerSlice.actions

export { customerReducer, customerActions }
const ImageBackground = () => {
  return (
    <figure>
      <img
        className="hidden aspect-3 object-cover object-center md:block  xl:aspect-5"
        src={"images/landing-image.jpg"}
        alt="hero"
      />
    </figure>
  );
};
export default ImageBackground;

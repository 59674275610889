import React, { InputHTMLAttributes, useEffect } from "react";
import { RegisterOptions } from "react-hook-form";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  registerRest?: any
  registerOptions?: RegisterOptions
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      registerRest,
      children,
      type = "text",
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-2xl",
      ...args
    },
    ref
  ) => {
    return (
      <div className="flex flex-col gap-2">
        <input
          type={type}
          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className} `}
          {...registerRest}  
          {...args}
          ref={e => {
            registerRest?.ref(e)
            return ref
          }}
        />
      </div>
    );
  }
);

export default Input;

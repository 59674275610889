import { FC } from "react";
import { Helmet } from "react-helmet";
import { LandingSection } from "components/LandingSection";
import SectionGridFilterCard from "./components/SectionGridFilterCard";
import { BackgroundSection } from "components/BackgroundSection";
import { SectionSlider } from "components/SectionSlider";
import { useTranslation } from "react-i18next";
import { SearchCarsProvider } from "./context/SearchCarsContext";

export interface ListingCarPageProps {
  className?: string;
}
const ListingCarPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >
      <Helmet>
        <title>Tourbooking</title>
      </Helmet>

      <div className="relative">
        <SearchCarsProvider>
          <LandingSection
            currentTab="landing-searchTab-oneDay"
            className=" mx-auto pb-24 lg:pb-16 w-11/12"
          />

          <SectionGridFilterCard className="container pb-24" />
        </SearchCarsProvider>

        <div className="container relative py-12 mb-12 ">
          <BackgroundSection />
          <SectionSlider
            heading={t("itemRecommendations-title")}
            subHeading={t("itemRecommendations-subtitle")}
            categoryCardType="card5"
            itemPerRow={4}
            uniqueClassName="ListingCarMapPage"
          />
        </div>
      </div>
    </div>
  );
};

export default ListingCarPage;

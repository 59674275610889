import { useEffect } from "react";
import { useAppDispatch } from "store/hooks/useAppStore";
import { fetchAmenities } from "store/slices/amenitiesSlice";
import { fetchCountries } from "store/slices/countriesSlice";
import { fetchCustomerData, customerActions } from "store/slices/customerSlice";
import { fetchItemTypes } from "store/slices/itemsTypeSlice";
import { useAmenitiesState } from "./useAmenitiesState";
import { useCountriesState } from "./useCountriesState";
import { useCustomerState } from "./useCustomerState";
import { useItemTypesState } from "./useItemTypesState";

// This hook must load GLOBAL data that must be initializated instantly before render the app
const useLoadGlobalData = () => {
  const { fetchCountriesStatus } = useCountriesState();
  const { fetchAmenitiesStatus } = useAmenitiesState();
  const { fetchCustomerStatus } = useCustomerState();
  const { fetchItemTypesStatus } = useItemTypesState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchCountriesStatus === "IDLE") {
      dispatch(fetchCountries());
    }
  }, []);

  useEffect(() => {
    if (fetchAmenitiesStatus === "IDLE") {
      dispatch(fetchAmenities());
    }
  }, []);

  useEffect(() => {
    if (fetchCustomerStatus === "IDLE") {
      const token = localStorage.getItem("auth-token");

      if (!token) {
        dispatch(customerActions.setCustomerData({ customer: null }));
        return;
      }

      const id_customer = localStorage.getItem("id_customer");
      dispatch(fetchCustomerData(id_customer as string));
    }
  }, []);

  useEffect(() => {
    if (fetchItemTypesStatus === "IDLE") {
      dispatch(fetchItemTypes());
    }
  }, []);
};

export { useLoadGlobalData };

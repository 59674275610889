import React, { FC } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import { LandingSection } from "components/LandingSection";
import { SectionSlider } from "components/SectionSlider";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./components/SectionGridHasMap";
import { Helmet } from "react-helmet";
import heroRightImage from "images/hero-right-car.png";
import { useTranslation } from "react-i18next";
import { SearchCarsProvider } from "./context/SearchCarsContext";

export interface ListingCarMapPageProps {
  className?: string;
}

const ListingCarMapPage: FC<ListingCarMapPageProps> = ({ className = "" }) => {


  const { t } = useTranslation();
  return (
    <div
      className={`nc-ListingCarMapPage relative ${className}`}
      data-nc-id="ListingCarMapPage"
    >
      <Helmet>
        <title>Tourbooking</title>
      </Helmet>
      <BgGlassmorphism />

      {/* SECTION */}

      <SearchCarsProvider>
        <div className="overflow-hidden">
          {/* SECTION 1 */}
          <div className="flex justify-center w-full pt-10 pb-24 lg:pt-16 ">
            <LandingSection
              className="pb-24 lg:pb-16 w-11/12"
              currentTab="landing-searchTab-oneDay"
              listingType={
                <>
                  <i className="text-2xl las la-car"></i>
                  <span className="ml-2.5">1512 cars</span>
                </>
              }
            />
          </div>
          <div className="pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none">
            <SectionGridHasMap />
          </div>

          <div className="relative py-16">
            <BackgroundSection />
            <SectionSlider
              heading={t("itemRecommendations-title")}
              subHeading={t("itemRecommendations-subtitle")}
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName="ListingCarMapPage"
            />
          </div>
        </div>
      </SearchCarsProvider>
    </div>
  );
};

export default ListingCarMapPage;

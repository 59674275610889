import { loginAsCustomer } from "api/customerApi";
import { FormControl } from "components/FormControl";
import {
  patternRule,
  requiredRule,
  trimInputRule,
} from "helpers/formRulesHelper";
import { Helmet } from "react-helmet";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { useAppDispatch } from "store/hooks/useAppStore";
import { customerActions } from "store/slices/customerSlice";
import { ILoginCustomer } from "types/Customer/Customer";

interface ILoginForm {
  email: string;
  password: string;
}

const PageLogin = () => {
  const methods = useForm<ILoginForm>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<ILoginForm> = async (data: ILoginCustomer) => {
    const { error, token, customer } = await loginAsCustomer(data);

    if (error) return alert("Error en el correo o contraseña");

    localStorage.setItem("auth-token", token);
    localStorage.setItem("id_customer", String(customer.id_customer));
    localStorage.setItem("customer", JSON.stringify(customer));
    dispatch(customerActions.setCustomerData({ customer }));
    history.push("/");
    return;
  };

  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("auth-login")}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <FormProvider {...methods}>
            <form
              className="grid grid-cols-1 gap-6"
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              <FormControl
                id="login-customer-email"
                name="email"
                label={t("email")}
                registerOptions={{
                  required: requiredRule(t("requiredField")),
                  pattern: patternRule(
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    t("invalidEmail")
                  ),
                }}
              >
                {(field: any) => (
                  <Input
                    type="email"
                    placeholder="johndoe@example.com"
                    {...field}
                  />
                )}
              </FormControl>
              <FormControl
                id="login-customer-password"
                name="password"
                label={t("password")}
                registerOptions={{
                  required: requiredRule(t("requiredField")),
                  validate: trimInputRule(t("invalidField")),
                }}
              >
                {(field: any) => (
                  <Input
                    type="password"
                    placeholder="*** *** ***"
                    className="mt-1"
                    {...field}
                  />
                )}
              </FormControl>
              <ButtonPrimary type="submit">{t("continue")}</ButtonPrimary>
            </form>
          </FormProvider>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t("auth-login-notAccountYet")}{" "}
            <Link className="text-primary-6000" to="/signup">
              {t("auth-login-createAccount")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;

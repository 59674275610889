import { UserIcon, CalendarIcon, XIcon } from "@heroicons/react/outline";
import { PHOTOS } from "../constants";
import { FormControl } from "components/FormControl";
import moment, { Moment } from "moment";
import { t } from "i18next";
import Input from "shared/Input/Input";
import { useFormContext } from "react-hook-form";
import { Guests } from "../types/Guests";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Item } from "types/Item/Item";
import { Dispatch, SetStateAction, useState, useMemo, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { getTourDayPrice } from "helpers/reservations/calculatePrices";
import api from "api/api";
import { useAppSelector } from "store/hooks/useAppStore";
import { Dictionary, EntityState } from "@reduxjs/toolkit";
import TourSelector from "./TourSelector";

export const RenderSidebar = ({
  selectedDate,
  guests,
  createReservation,
  currentItem,
  currentStepIndex,
  total,
  subtotal,
  taxes,
  submitPayment,
  setSubmitPayment,
  discountCode,
  setDiscountCode,
  isValidDiscountCode,
  setIsValidDiscountCode,
}: {
  selectedDate: Moment | null;
  guests: Guests;
  createReservation: () => any;
  currentItem: Item | undefined;
  total?: number;
  subtotal?: number;
  taxes?: number;
  currentStepIndex: number;
  submitPayment: boolean;
  setSubmitPayment: Dispatch<SetStateAction<boolean>>;
  discountCode: string;
  setDiscountCode: Dispatch<SetStateAction<string>>;
  isValidDiscountCode: number;
  setIsValidDiscountCode: Dispatch<SetStateAction<number>>;

}) => {

  const createReservation_ = () => {
    console.log('createReservation antes del cambio', submitPayment);
    setSubmitPayment(true);
  }

  const [discount, setDiscount] = useState<number>(0);

  const checkIfPromoCode = async () => {
    const response = await api.get<any>(`/promotion-codes/validate/${discountCode}`).catch((err) => {
      const error = err.response.data.message;
      toast.error(error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    });
    if(response){
      const discount = response?.data?.promotion_code?.discount || 0;
      setDiscount(discount);
      setIsValidDiscountCode(discount);
      toast.success('Valid Promo Code!', {
        position: "bottom-right",
        autoClose: 5000,
     
        theme: "light",
        progressClassName: "green",
        className: ""
        });
    }
  }

  const price_adults = useMemo(() => {
    const selectedDate_ = selectedDate ? selectedDate : moment();
    return getTourDayPrice(selectedDate_, currentItem, 'adults');
  }, [selectedDate])

  const price_children = useMemo(() => {
    const selectedDate_ = selectedDate ? selectedDate : moment();
    return getTourDayPrice(selectedDate_, currentItem, 'children');
  }, [selectedDate])

  const price_infants = useMemo(() => {
    const selectedDate_ = selectedDate ? selectedDate : moment();
    return getTourDayPrice(selectedDate_, currentItem, 'infants');
  }, [selectedDate])


  return (
    <div className="listingSectionSidebar__wrap shadow-lg p-8 mb-8 md:md-0">
      <div className="render_pc">
          <TourSelector></TourSelector>
      </div>
      <img src={PHOTOS[0]} alt="" />
      <div>
        <div className="flex items-center">
          <UserIcon width={15} height={15} className="mr-2" />
          <p>{guests.guestAdults} adults x ${price_adults}</p>
        </div>
        <div className="flex items-center">
          {!(guests.guestChildren == 0) && (
            <>
              <UserIcon width={15} height={15} className="mr-2" />
              <p>{guests.guestChildren} children x ${price_children}</p>
            </>
          )}
        </div>
        <div className="flex items-center">
          {!(guests.guestInfants == 0) && (
            <>
              <UserIcon width={15} height={15} className="mr-2" />
              <p>{guests.guestInfants} infants x ${price_infants}</p>
            </>
          )}
        </div>
        <div className="flex items-center">
          <CalendarIcon width={15} height={15} className="mr-2" />
          <p>{moment(selectedDate).format("DD/MM/YYYY")}</p>
        </div>
        <div className="flex items-center">
          <XIcon width={15} height={15} className="mr-2" />
          <p> Non-refundable</p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-between lg:items-center mt-2">
        <Input
          type={"text"}
          value={discountCode}
          onChange={e => setDiscountCode(e.target.value)}
          placeholder="Enter Promo Code..."
          className="w-full lg:w-auto lg:mr-2"
        />
        {!isValidDiscountCode && (
          <ButtonPrimary onClick={checkIfPromoCode} className="w-full lg:w-auto mt-2 lg:mt-0">
            Add Promo
          </ButtonPrimary>
        )}
      </div>

      {/* PRICE */}
      <div className="flex flex-row justify-between items-center" style={{ marginTop: 10 }}>
        <div className="flex flex-col">
          <span>Subtotal: <b className="font-semibold">${ ((subtotal || 0)/(1 - discount / 100))?.toFixed(2)}</b></span>
          {
            discount > 0 && (
              <span>Promo: <b className="font-semibold">- ${((subtotal || 0) * (discount / 100)/(1 - discount / 100))?.toFixed(2)}</b></span>
            )
          }
          <span>Total: <b className="font-semibold">${subtotal?.toFixed(2)}</b></span>
          <span>Taxes: <b className="font-semibold">${taxes?.toFixed(2)}</b></span>
        </div>
        <div className="flex flex-row items-center">
          <span className="text-3xl font-semibold">${total}</span>
          <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">total</span>
        </div>
      </div>

      <ButtonPrimary disabled={currentStepIndex == 2 ? false : true} onClick={createReservation_}>Submit</ButtonPrimary>
    </div>
  );
};

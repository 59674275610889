import React, { TextareaHTMLAttributes } from "react";
import { RegisterOptions } from "react-hook-form";

export interface TextareaProps
extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  registerOptions?: RegisterOptions
  registerRest?: any
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ registerRest, className = "", children, ...args }, ref) => {
    return (
      <div>
        <textarea
          className={`block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${className}`}
          rows={4}
          {...args}
          {...registerRest}
          ref={(e) => {
            registerRest.ref(e)
            return ref
          }}
        >
          {children}
        </textarea>
      </div>
    );
  }
);

export default Textarea;

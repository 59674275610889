import { createAsyncThunk, createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { getItems } from "api/itemsApi";
import { Item } from "types/Item/Item";

export const fetchItems = createAsyncThunk('items/fetchItems', getItems)

const resortsAdapter = createEntityAdapter<Item>({
  selectId: (item) => item.id_item,
  sortComparer: (a, b) => b.id_item - a.id_item
})

export const resortsSlice = createSlice({
  name: 'cars',
  initialState: resortsAdapter.getInitialState({
    error: false,
    fetchItemsStatus: 'IDLE'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchItems.pending, (state, action) => {
      state.fetchItemsStatus = 'PENDING'
    })

    builder.addCase(fetchItems.fulfilled, (state, action) => {
      state.fetchItemsStatus = 'DONE'
      resortsAdapter.setAll(state, action.payload.items as Item[] ?? [])
    })

    builder.addCase(fetchItems.rejected, (state, action) => {
      state.error = true
      state.fetchItemsStatus = 'ERROR'
    })
  }}
)

export const resortsSelector = resortsAdapter.getSelectors((state: any) => state.resorts)
export const resortsReducer = resortsSlice.reducer

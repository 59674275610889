import React, { FC, useEffect, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Heading2 from "components/Heading/Heading2";
import CarCardH from "components/CarCardH/CarCardH";
import { useResortsState } from "hooks/useResortsState";
import { resortsSelector } from "store/slices/resortsSlice";
import { useLoadListingResortPage } from "../hooks/useLoadListingResortPage";
import { useAppSelector } from "store/hooks/useAppStore";
import { useTranslation } from "react-i18next";
import { ResortsMap } from "./ResortsMap";
import { Coordinates } from "types/Coordinates/Coordinates";
import { provincesCoordinatesMap } from "constants/provincesCoordinates";
import { useSearchCarsContext } from "../hooks/useSearchCarsContext";

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  useLoadListingResortPage();
  const data = useAppSelector(resortsSelector.selectAll);
  const { fetchItemsStatus } = useResortsState() ?? {};

  const { t } = useTranslation();
  const { searchFilters } = useSearchCarsContext();
  const [mapCoordinates, setMapCoordinates] = useState<Coordinates>(
    provincesCoordinatesMap[searchFilters.location]
  );

  useEffect(() => {
    setMapCoordinates(provincesCoordinatesMap[searchFilters.location]);
  }, [searchFilters.location]);

  if (fetchItemsStatus === "PENDING") {
    return (
      <div className="flex justify-centser mb-16">
        <h4 className="text-5xl animate-pulse">{t("itemList-loading-text")}</h4>
      </div>
    );
  }

  if (fetchItemsStatus === "ERROR") {
    return (
      <div className="flex gap-6 flex-col items-center justify-center mb-16">
        <h4 className="text-5xl  font-bold text-red-500">
          {t("itemList-error-title")}
        </h4>
        <h4 className="text-2xl text-red-500">
          {t("itemList-error-subtitle")}
        </h4>
      </div>
    );
  }

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2
            heading={t("gridHasMap-title")}
            subHeading={
              <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                {data?.length === 1
                  ? t("resortWithCount", { count: 1 })
                  : t("pluralResortWithCount", { count: data?.length })}
                <span className="mx-2">·</span>
                {t("today")}
              </span>
            }
          />
          {/* <div className="mb-8 lg:mb-11">
            <TabFilters />
          </div> */}
          <div className="grid grid-cols-1 gap-8">
            {(data || []).map((item) => (
              <div key={item.id_item}>
                <CarCardH data={item} />
              </div>
            ))}
          </div>
          {/* <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div> */}
        </div>

        {/* MAPPPPP */}
        <div className={`xl:flex-grow xl:static xl:block`}>
          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <ResortsMap coordinates={mapCoordinates} resorts={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;

import {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom"

const formatTime = (time: number): string => {
    let minutes: any = Math.floor(time / 60);
    let seconds: any = Math.floor(time - minutes * 60) 

    if(minutes <= 10) minutes = "0" + minutes;
    if(seconds <= 10) seconds = "0" + seconds;

    return minutes + ":" + seconds;
}

interface TimerProps { 
    seconds: number;
    currentStepIndex: number;
}

export default function Timer({seconds, currentStepIndex}: TimerProps){
    
    const [countdown, setCountdown] = useState(seconds);
    const [active, setActive] = useState<boolean>(true);
    const timerID = useRef<NodeJS.Timeout>();
    const history = useHistory();

    useEffect(()=>{
        timerID.current = setInterval(()=>{
            setCountdown(prev => prev - 1);
        },1000);

        return () => clearInterval(timerID.current);
    },[]);

    useEffect(()=>{
        if(currentStepIndex == 2){
            setActive(false);
            clearInterval(timerID.current);
        }
    }, [currentStepIndex]);

    useEffect(()=>{
        if(countdown <= 0){
            clearInterval(timerID.current);
            // Redirect to page or smth.
        }
    })


    return(
        <div className={`text-center py-2 my-2 ${active ? 'bg-yellow-200' : 'hidden'} rounded-md`}>
            {
                active ? <p>¡Reserva ahora! Solo te quedan <b>{formatTime(countdown)}</b> minutos</p> 
                       : <p>Reservación Creada!</p>
            }
        </div>
    )
}
import { ICountry } from 'types/Country'
import api from "./api"

const API_ENDPOINT = '/data/countries'

export interface ICountriesResponse {
  message: string,
  error: boolean,
  countries?: ICountry[],
}

const getCountries = async () => await (await api.get<ICountriesResponse>(API_ENDPOINT)).data

export { getCountries } 
import { useCustomerState } from "hooks/useCustomerState";
import { useLoadGlobalData } from "hooks/useLoadGlobalData";
import { Redirect } from "react-router-dom";

interface AuthUserProps {
  children: JSX.Element | JSX.Element[];
}

const AuthUser = ({ children }: AuthUserProps) => {
  useLoadGlobalData();
  const { customer, fetchCustomerStatus } = useCustomerState();

  if (fetchCustomerStatus === "DONE" && !customer) {
    return <Redirect to={"/"} />;
  }

  return <>{children}</>;
};

export default AuthUser;

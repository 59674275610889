import React, { FC, useMemo } from "react";
import { CarDataType } from "data/types";
import Heading2 from "components/Heading/Heading2";
import TourCard from "components/TourCard/TourCard";
import { useResortsState } from "hooks/useResortsState";
import { useLoadListingResortPage } from "../hooks/useLoadListingResortPage";
import { resortsSelector } from "store/slices/resortsSlice";
import { useAppSelector } from "store/hooks/useAppStore";
import { useTranslation } from "react-i18next";
import { useSearchCarsContext } from "../hooks/useSearchCarsContext";
import { getReservedData } from "helpers/reservations/checkIfIsReserved";
import moment from "moment";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  useLoadListingResortPage();
  const { t } = useTranslation();

  const data = useAppSelector(resortsSelector.selectAll ?? []);
  const { fetchItemsStatus } = useResortsState() ?? {};

  const { searchInitReservationDate, searchEndReservationDate, searchFilters } =
    useSearchCarsContext();

  const filterByType = (carType: string | undefined) =>
    searchFilters.itemType ? searchFilters.itemType === carType : true;

  const filterByPriceRange = (price: number) =>
    price >= searchFilters.pricesRange[0] &&
    price <= searchFilters.pricesRange[1];

  const filteredCars = useMemo(
    () =>
      data
        ?.filter((car) => Boolean(filterByType(car.type?.detail_item_type)))
        .filter((car) => Boolean(filterByPriceRange(car.amount_item))),
    [data, searchFilters]
  );

  if (fetchItemsStatus === "PENDING") {
    return (
      <div className="flex justify-center mb-16">
        <h4 className="text-5xl animate-pulse">{t("itemList-loading-text")}</h4>
      </div>
    );
  }

  if (fetchItemsStatus === "ERROR") {
    return (
      <div className="flex gap-6 flex-col items-center justify-center mb-16">
        <h4 className="text-5xl  font-bold text-red-500">
          {t("itemList-error-title")}
        </h4>
        <h4 className="text-2xl text-red-500">
          {t("itemList-error-subtitle")}
        </h4>
      </div>
    );
  }

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={`${t("gridFilterSection-title")} Costa Rica`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {/* Items.length */}
            {filteredCars?.length === 1
              ? t("resortWithCount", { count: 1 })
              : t("pluralResortWithCount", { count: filteredCars?.length })}
            <span className="mx-2">·</span>
            {t("today")}
          </span>
        }
      />

      <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>

      {/* Map dataa */}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {filteredCars?.map((tour) => (
          <TourCard
            key={tour.id_item}
            data={tour}
            targetDate={searchInitReservationDate}
            endDate={searchEndReservationDate}
            reservedData={getReservedData(
              tour,
              moment(searchInitReservationDate),
            )}
          />
        ))}
      </div>

      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;

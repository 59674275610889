import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "shared/Checkbox/Checkbox";
import { insuranceAssignment, Item } from "types/Item/Item";

interface InsurancesSectionProps {
  currentItem?: Item;
  setSelectedInsurances: Dispatch<SetStateAction<insuranceAssignment[]>>;
  selectedInsurances: insuranceAssignment[];
}

const InsurancesSection = ({
  currentItem,
  selectedInsurances,
  setSelectedInsurances,
}: InsurancesSectionProps) => {
  const { insurance_assignments } = currentItem ?? {};
  const { t } = useTranslation();

  const onSelectCheckbox = (id: number) => {
    const insuranceObject = insurance_assignments?.find(
      ({ id_insurance }) => id_insurance === id
    );

    if (insuranceObject) {
      const isSelected = selectedInsurances.find(
        ({ id_insurance }) => insuranceObject.id_insurance === id_insurance
      );

      if (isSelected) {
        setSelectedInsurances((prev) =>
          prev.filter(
            (insurance) =>
              insurance.id_insurance !== insuranceObject.id_insurance
          )
        );

        return;
      }

      setSelectedInsurances((prev) => [...prev, insuranceObject]);
    }
  };

  return (
    <div className="listingSection__wrap">
      <div>
        <h2 className="text-2xl font-semibold">
          {t('insurances')}
        </h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {t('carDetail-insurances-description')}
        </span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
        {insurance_assignments?.map(({ insurance, price, IS_REQUIRED }) => (
          <div key={insurance.id_insurance} className="flex items-center gap-4">
            <Checkbox
              defaultChecked={IS_REQUIRED}
              disabled={IS_REQUIRED}
              label={insurance.detail_insurance}
              name={`insurance-${insurance.id_insurance}`}
              key={insurance.id_insurance}
              onChange={() => onSelectCheckbox(insurance.id_insurance)}
            />

            {!!price && <span className="font-bold">(${price})</span>}
          </div>
        ))}
      </div>
    </div>
  )
};

export default InsurancesSection;

import { BacPaymentData, APIStripePaymentData } from "types/Payment";
import api from "./api";
import paymentGateway from "./api-gateway-payment";

const API_ENDPOINT = "/payWithCard";
const API_STRIPE_ENDPOINT = "/stripePayment";
const API_PAYMENT_GATEWAY_ENDPOINT = "/payment/show";
const AUTH_TOKEN = "8r7BBD64yRxUTydR2ZhwNESWnVGcM3AaeKR"; // This token allows the connection between FRONTEND and API in order to control the payment requests.
const PAYMENT_METHODS = {
  bac: "bac",
  stripe: "stripe",
};

const currentPaymentMethod = PAYMENT_METHODS.stripe;
const currentPaymentMethodBac = PAYMENT_METHODS.bac;

const createPayment = async (
  newPaymentData: any
) =>
  (
    await api.post(API_ENDPOINT, newPaymentData, {
      headers: {
        token: AUTH_TOKEN,
        gangwayType: newPaymentData?.cardholderName ? currentPaymentMethodBac :  currentPaymentMethod,
      },
    })
  ).data;

  const createPaymentBacApiPaymentGateway = async (
    newPaymentData?: BacPaymentData
  ) =>
    (
      await paymentGateway.get(API_PAYMENT_GATEWAY_ENDPOINT,{
        params: {
          amount: newPaymentData?.amount,
          currency: "USD",
          cardnumber: newPaymentData?.cardPan,
          cardccv: newPaymentData?.cardCvv,
          cardname: newPaymentData?.cardholderName,
          cardexpiredate: newPaymentData?.cardExpiration,
        },
      })
    );

  const createPaymentFormApi = async (
    dataparameter: any
  ) => {
     const {data} =  await api.post(API_STRIPE_ENDPOINT, dataparameter, {
        headers: {
          "Content-Type": "application/json"
        },
      });

      return data;
  }

  

export { createPayment, currentPaymentMethod, createPaymentFormApi, createPaymentBacApiPaymentGateway };

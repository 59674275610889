import StartRating from "components/StartRating/StartRating";
import LikeSaveBtns from "containers/ListingDetailPage/LikeSaveBtns";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { Item } from "types/Item/Item";
import { useTranslation } from "react-i18next";

interface IOverviewSectionProps {
  currentItem?: Item;
}

const OverviewSection = ({ currentItem }: IOverviewSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className="listingSection__wrap space-y-6">
      <div className="flex justify-between items-center">
        <LikeSaveBtns />
      </div>

      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
        {currentItem?.detail_item}
      </h2>

      <div className="flex items-center space-x-4">
        <StartRating />
        <span>
          <i className="las la-map-marker-alt"></i>
          <span className="ml-1">{currentItem?.location?.detail_location}</span>
        </span>
      </div>

      <div className="flex items-center">
        <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
        <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
          {`${t("owner")}: `}
          <span className="text-neutral-900 dark:text-neutral-200 font-medium">
            {currentItem?.owner?.name}
          </span>
        </span>
      </div>

      {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

      <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
        <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
          <i className="las la-user-friends text-2xl"></i>
          <span className="">Familiar</span>
        </div>
        <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
          <i className="las la-dharmachakra text-2xl"></i>
          <span className="">Exploración</span>
        </div>
        <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
          <i className="las la-suitcase text-2xl"></i>
          <span className="">Viajes</span>
        </div>
      </div> */}
    </div>
  );
};

export default OverviewSection;

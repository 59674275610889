import { BackgroundSection } from "components/BackgroundSection";
import { SectionSlider } from "components/SectionSlider";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { useTranslation } from "react-i18next";

const RecommendationsSection = () => {
  const { t } = useTranslation();
  return (
    <div className="container py-24 lg:py-32">
      {/* SECTION 1 */}
      <div className="relative py-16">
        <BackgroundSection />
        <SectionSlider
          heading={t("itemRecommendations-title")}
          subHeading={t("itemRecommendations-subtitle")}
          categoryCardType="card5"
          itemPerRow={5}
          sliderStyle="style2"
          uniqueClassName="ListingCarDetailPage"
        />
      </div>

      {/* SECTION */}
      <SectionSubscribe2 className="pt-24 lg:pt-32" />
    </div>
  );
};

export default RecommendationsSection;

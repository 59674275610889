import LandingSearchFilters, {
  SearchTab,
} from "components/HeroSearchForm/LandingSearchForm";

interface SearchBarProps {
  currentTab: SearchTab;
}

const SearchBar = ({ currentTab }: SearchBarProps) => {
  return (
    <div className="flow-root w-full">
      <div className="z-10 relative top-12 w-full md:absolute">
        <LandingSearchFilters
          className="mb-12 mx-auto md:mb-0"
          currentTab={currentTab}
        />
      </div>
    </div>
  );
};
export default SearchBar;

import GoogleMapReact from "google-map-react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import { useTranslation } from "react-i18next";

const LocationSection = () => {
  const { t } = useTranslation();
  return (
    <div className="listingSection__wrap">
      <div>
        <h2 className="text-2xl font-semibold">{t("location")}</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Naranjo, Alajuela
        </span>
      </div>

      <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
        <div className="rounded-xl overflow-hidden">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
            }}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={{
              lat: 55.9607277,
              lng: 36.2172614,
            }}
          >
            <LocationMarker lat={55.9607277} lng={36.2172614} />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
};

export default LocationSection;

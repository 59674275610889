import React, { useEffect, useState, FC, Dispatch, SetStateAction } from "react";
import { env } from "env";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CheckoutProps } from "containers/StripeSuccesfulPage/types/CheckoutProps";
import { ReservationResponse } from "containers/TourModalPage/types/ReservationRespose";
import api from "api/api";
import FullScreenLoaderComponent from "components/FullScreenLoaderComponent";

const CheckoutForm= ({
  submitPayment,
  setResgisterStatus,
  reservation,
  createReservation
}:{
  submitPayment: boolean | null;
  setResgisterStatus: Dispatch<SetStateAction<number>>;
  reservation?: ReservationResponse;
  createReservation: () => Promise<ReservationResponse>;
}) =>  {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<String>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  useEffect(()=>{
    if(submitPayment != null){
      handleSubmit();
    }
  }, [submitPayment]);

  const confirmPayment = async (id_reservation : any) => {
    await api.post('/confirmPayment', {
      id_reservation,
      supplier: 'stripe'
    });
  }

  const handleSubmit = async () => {

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const res = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${env}/api/confirmStripePayment`,
      },
      redirect: "if_required"
    });

    if (res.paymentIntent?.status === "succeeded") {
      setResgisterStatus(1);
      var { reservation} = await createReservation();
      confirmPayment(reservation?.id_reservation);
      setIsLoading(false);
      setMessage("Payment succeeded!");
    } else {
      setMessage(`Payment failed ${res.error?.message}`);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <input type="text" autoFocus={true} style={{ display: 'none' }} />
      <PaymentElement id="payment-element" />
      {message && <div id="payment-message">{message}</div>}
      <FullScreenLoaderComponent isLoading={isLoading} />
    </form>
  );
}

export default CheckoutForm;
export const EN_TRANSLATION = {
  "navigation-home": "Home",
  "navigation-map": "View map",
  "auth-signup": "Sign up",
  "auth-signup-hasAccount": "Already have account?",
  "auth-login": "Log in",
  "auth-login-notAccountYet": "Not account yet?",
  "auth-login-createAccount": "Create account",
  "landing-title": "Search your dream tour",
  "landing-subtitle": "750+ tours available",
  "landing-searchTab-oneDay": "One day",
  "landing-searchTab-multipleDays": "Multiple days",
  "landing-searchTab-permanent": "Permanent",
  "landing-searchSubTab-samePlace": "Keep at same place",
  "landing-searchSubTab-differentPlace": "Keep at different places",
  "landing-search-provincePlaceholder": "Province or city",
  "landing-search-startDatePlaceholder": "Start date",
  "landing-search-endDatePlaceholder": "End date",
  "itemList-loading-text": "Loading tours and amazing experiences!",
  "itemList-error-title": "Oops! Something was wrong...",
  "itemList-error-subtitle": "Try again",
  "itemRecommendations-title": "Our recommendations",
  "itemRecommendations-subtitle": "Resorts and featured tours",
  "gridFilterSection-title": "Tours in",
  "gridHasMap-title": "Available tours",
  "carDetail-showAllImages": "Show all images",
  "resortDetail-conditions-description":
    "When reserving the tour  you agree to comply with the following conditions: ",
  "resortDetail-amenities-description":
    "This tour has the following amenities: ",
  "resortDetail-insurances-description":
    "This tour has the following insurances: ",
  "resortDetail-shouldKnownSection-title": "You should know",
  "resortDetail-shouldKnownSection-cancellationPolicy-title":
    "Cancellation policy",
  "resortDetail-shouldKnownSection-cancellationPolicy-description":
    "To cancel the reservation, 4 business days prior must be taken into account, as well as a discount of 20% of the amount attributed.",

  // Auth words translation
  fullName: "Full name",
  email: "E-mail",
  phoneNumber: "Phone number",
  country: "Country",
  address: "Address",
  password: "Password",
  repeatPassword: "Repeat password",
  invalidEmail: "Invalid email",
  requiredField: "Required field",
  invalidField: "Invalid field",
  invalidComparePasswords: "Passwords are not the same",
  ContactDetails: "Contact Details",
  ActivityDetails: "Activity Details",
  countryOfOrigin: "Origin Country",
  SpecialRequirements: "Special Requirements",
  TourLanguage: "Tour Language",
  PaymentDetails: "Payment Details",
  PromoCode: "Promo Code",
  Availability: "Availability",

  // Reservations
  reservationSuccess: "Successful reservation",
  reservationError: "Reservation error. Try later",
  sucessfulPayment: "Sucessful Payment!",
  goBack: "Go back",

  // Payment Modal
  "paymentModal-title": "Payment proccess",
  "paymentModal-personalInfo": "Personal Info",
  "paymentModal-cardInfo": "Card Info",
  "paymentModal-cardNumber": "Card number",
  "paymentModal-cardExpiration": "Expiration date",
  "paymentModal-cardHolderName": "Card holder name",
  "paymentModal-country": "Country of residence",
  firstName: "First name",
  lastName: "Last name",
  city: "City",
  state: "State",
  zipCode: "Zip code",
  invalidCardNumber: "Invalid card number",

  // Search filters
  "no-cars-found": "0 tours found",
  "searchFilterModal-title": "Search filters",
  "searchFilterModal-priceRange": "Price range",
  "searchFilterModal-pricePerDay": "Price per day",
  "searchFilterModal-minPrice": "Min price",
  "searchFilterModal-maxPrice": "Max price",
  "searchFilterModal-workingOnMoreFilters": "Working on more filters",

  // Select language
  spanish: "Spanish",
  english: "English",
  selectLanguage: "Select language",

  // Common words translation
  apply: "Apply",
  continue: "Continue",
  car: "Car",
  pluralCar: "Cars",
  resortWithCount: "{{ count }} tour",
  pluralResortWithCount: "{{ count }} tours",
  today: "Today",
  available: "Available",
  reserved: "Reserved",
  owner: "Owner",
  passengers: "Passengers",
  day: "Day",
  pluralDay: "Days",
  share: "Share",
  save: "Save",
  conditions: "Conditions",
  amenities: "Amenities",
  insurances: "Insurances",
  availability: "Availability",
  review: "Review",
  pluralReview: "Reviews",
  seeMore: "See more",
  location: "Location",
  rent: "Rent",
};

import BacCheckoutForm from "components/BacPaymentModal/BacCheckoutForm";
import CheckoutStripe from "containers/StripeSuccesfulPage/CheckoutFormPage";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect } from "react";
import { ReservationResponse } from "../types/ReservationRespose";
import { WizardStep } from "./WizardStep";

export const PaymentInformationForm = ({
  active = false,
  country = 53,
  total,
  submitPayment,
  setResgisterStatus,
  reservation,
  createReservation,
  setSubmitPayment
}: {
  total: number
  active?: boolean;
  country?: number;
  submitPayment: boolean;
  setSubmitPayment: Dispatch<SetStateAction<any>>
  setResgisterStatus: Dispatch<SetStateAction<number>>;
  reservation?: ReservationResponse;
  createReservation: () => Promise<ReservationResponse>;
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])

  return (
    <WizardStep active={active}>
      <div>
        <h2 className="text-2xl font-semibold">3. {t("PaymentDetails")}</h2>
      </div>
      {active && (
        <>
      
          {country === 53 
            ? <BacCheckoutForm amount={total} setSubmitPayment={setSubmitPayment} submitPayment={submitPayment} setResgisterStatus={setResgisterStatus} reservation={reservation} createReservation={createReservation}></BacCheckoutForm> 
            : <CheckoutStripe amount={total} submitPayment={submitPayment} setResgisterStatus={setResgisterStatus} reservation={reservation} createReservation={createReservation}></CheckoutStripe>
          }
        </>
      )}
    </WizardStep>
  );
};

import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { UserIcon, CogIcon, CurrencyDollarIcon } from "@heroicons/react/outline";
import { Link } from 'react-router-dom';
import { MenuOptionProps } from "./types/MenuOption";
import { useTranslation } from "react-i18next";
import { AiFillCar, AiOutlineUser } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";

const MenuOptionsArray: MenuOptionProps[] = [
  // {
  //   title: "my-account",
  //   path: "/account",
  //   Icon: FaMoneyCheckAlt,
  // },
  // {
  //   title: "my-vehicles",
  //   path: "/account/vehicles",
  //   Icon: AiFillCar,
  // },
  // {
  //   title: "my-rents",
  //   path: "/account/rents",
  //   Icon: AiOutlineUser,
  // },
]

export default function AccountDropdown({ username, isAgent }: { username: string, isAgent: boolean }) {
  return (
    <div className="top-16 text-right">
      <Menu as="div" className={`relative inline-block text-left`}>
        <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {username} {isAgent ? "(Agent)" : ""}
          <UserIcon
            className="ml-2 -mr-1 h-5 w-5 text-teal-700"
            aria-hidden="true"
          />
        </Menu.Button>
        <SubMenu />
      </Menu>
    </div>
  )
}

function SubMenu(): JSX.Element {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <MenuOptions />
      </Menu.Items>
    </Transition>
  )
}

function MenuOptions(): JSX.Element {
  return (
    <>
      {MenuOptionsArray.map((menu_option_props, i) => {
        return <MenuOption key={`menu_option_${i}`} {...menu_option_props} />
      })}
    </>
  );
}

function MenuOption({ path, title, Icon }: MenuOptionProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="px-1 py-1">
      <Menu.Item>
        {({ active }) => (
          <Link to={path}>
            <button
              className={`${active ? 'bg-teal-700 text-white' : 'text-gray-900'
                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
              {<Icon style={{ marginRight: '15px' }} />}
              {t(title)}
            </button>
          </Link>
        )}
      </Menu.Item>
    </div>
  )
}
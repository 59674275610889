import { FiltersModal } from "./FiltersModal";
import { ItemTypeNav } from "./ItemTypeNav";

interface FiltersProps {}

const Filters = (props: FiltersProps) => {
  return (
    <>
      <div className="flex items-center">
        <ItemTypeNav />
        <FiltersModal />
      </div>
    </>
  );
};
export default Filters;
